import Carousel from 'react-bootstrap/Carousel';
const Tool1 = () => {
  return (
    <Carousel indicators={false} className='home-products-slides'>
         <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/172.jpg"
          alt="First slide"
        />
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/177.jpg"
          alt="First slide"
        />
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/183.jpg"
          alt="First slide"
        />
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/189.jpg"
          alt="First slide"
        />
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/194.jpg"
          alt="First slide"
        />
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/82.jpg"
          alt="First slide"
        />
      </Carousel.Item>   
    </Carousel>
  )
}
export default Tool1