
import React, {useEffect, useState} from 'react';

import {FaArrowRight, FaTimes} from 'react-icons/fa';



const Team = () => {
    useEffect(()=>{
document.title = "Wardrobes | Pinnacle Concepts Limited";
},[]);


  return (
    <section className='section-cover team-cover equip-cover' >
         <div className='content-container products-container'>
          
          <h2 className="home-h2 furniture-h2">Wardrobes</h2>
             {/* <p className='about-intro-p'>Exotic Furniture</p> */}
         
            <div className='services-container-new'>
                <article className='single-service-new'>
    
              <div className='tools-slides-cover'>
                {/* <Tool1></Tool1> */}
                <img src='./images/148.jpg' class='item-image '></img>
              </div>
            <h3 className='skill-h3 service-h3-new'>PC/HF/W/001</h3>
          </article>
                <article className='single-service-new'>
    
              <div className='tools-slides-cover'>
                {/* <Tool1></Tool1> */}
                <img src='./images/149.jpg' class='item-image '></img>
              </div>
            <h3 className='skill-h3 service-h3-new'>PC/HF/W/002</h3>
          </article>
          <article className='single-service-new'>
    
              <div className='tools-slides-cover'>
                {/* <Tool1></Tool1> */}
                <img src='./images/150.jpg' class='item-image'></img>
              </div>
            <h3 className='skill-h3 service-h3-new'>PC/HF/W/003</h3>
          </article>
          <article className='single-service-new'>
         
              <div className='tools-slides-cover'>
                {/* <Tool2></Tool2> */}
                <img src='./images/151.jpg' class='item-image'></img>
              </div>
            <h3 className='skill-h3 service-h3-new'>PC/HF/W/004</h3>
          
          </article>
          <article className='single-service-new'>
         
              <div className='tools-slides-cover'>
                {/* <Tool2></Tool2> */}
                <img src='./images/152.jpg' class='item-image'></img>
              </div>
            <h3 className='skill-h3 service-h3-new'>PC/HF/W/005</h3>
          
          </article>
          <article className='single-service-new'>
         
              <div className='tools-slides-cover'>
                {/* <Tool2></Tool2> */}
                <img src='./images/153.jpg' class='item-image'></img>
              </div>
            <h3 className='skill-h3 service-h3-new'>PC/HF/W/006</h3>
          </article>
      </div>
         </div>
    </section>
  )
}

export default Team