import React, {useEffect} from 'react'

const Clients = () => {
useEffect(()=>{
document.title = 'Clients | Pinnacle Concepts Limited';

},[]);



  return (
    <section className='section-cover clients-cover' >
          <h2 className="home-h2 home-h2-b">Our Clients</h2>
          {/* <p className='clients-para'></p> */}
        <div className='content-container clients'>

            <article className='client'>
                      <img src='./images/205.jpg' alt='client' className='client-image'></img>
           </article>
           <article className='client'>
                      <img src='./images/206.png' alt='client' className='client-image'></img>
           </article>
           <article className='client'>
                      <img src='./images/207.png' alt='client' className='client-image'></img>
           </article>
            <article className='client'>
                      <img src='./images/208.png' alt='client' className='client-image'></img>
           </article>
            <article className='client'>
                      <img src='./images/209.png' alt='client' className='client-image'></img>
           </article>
            <article className='client'>
                      <img src='./images/210.png' alt='client' className='client-image'></img>
           </article>
            <article className='client'>
                      <img src='./images/211.jpg' alt='client' className='client-image'></img>
           </article>
            <article className='client'>
                      <img src='./images/212.png' alt='client' className='client-image'></img>
           </article>
            <article className='client'>
                      <img src='./images/213.jpg' alt='client' className='client-image'></img>
           </article>
            <article className='client'>
                      <img src='./images/214.jpg' alt='client' className='client-image'></img>
           </article>
            <article className='client'>
                      <img src='./images/215.jpg' alt='client' className='client-image'></img>
           </article>
            <article className='client'>
                      <img src='./images/216.png' alt='client' className='client-image'></img>
           </article>
            <article className='client'>
                      <img src='./images/217.jpg' alt='client' className='client-image'></img>
           </article>
            <article className='client'>
                      <img src='./images/218.jpg' alt='client' className='client-image'></img>
           </article>
            <article className='client'>
                      <img src='./images/219.jpg' alt='client' className='client-image'></img>
           </article>
            <article className='client'>
                      <img src='./images/220.png' alt='client' className='client-image'></img>
           </article>
            <article className='client'>
                      <img src='./images/221.jpg' alt='client' className='client-image'></img>
           </article>
            <article className='client'>
                      <img src='./images/222.png' alt='client' className='client-image'></img>
           </article>
              
            <article className='client'>
                      <img src='./images/223.jpg' alt='client' className='client-image'></img>
           </article>
           <article className='client'>
                      <img src='./images/224.png' alt='client' className='client-image'></img>
           </article>
            <article className='client'>
                      <img src='./images/225.png' alt='client' className='client-image'></img>
           </article>
            <article className='client'>
                      <img src='./images/226.jpg' alt='client' className='client-image'></img>
           </article>
            <article className='client'>
                      <img src='./images/227.png' alt='client' className='client-image'></img>
           </article>
        
           {/* <article className='client'>
                      
                      <img src='./images/c14.png' alt='client' className='client-image'></img>
             <p><strong></strong></p>
           </article>
           */}
          
          
          </div> 
    </section>
  )
}

export default Clients