import React, {useEffect} from 'react'
import CarouselFade from '../components/HomeCarousel';
import OfficeFurniture from '../components/Office-Furniture';
import HomeFurniture from '../components/Home-Furniture';
import CurtainsBlinds from '../components/Curtains-Blinds';
import DecorAccessories from '../components/Decor-Accessories';
import {Link} from 'react-router-dom';
import GoalsCarousel from '../components/GoalsCarousel';
import {FaCalendarPlus,FaHandshake,FaCheck,FaQuoteLeft, FaCertificate, FaUsers, FaFileAlt} from 'react-icons/fa';

let scrollFunc = () =>{
window.scrollTo({
   top:0,
behavior: "smooth",
});

}

const Home = () => {

  useEffect(()=>{
document.title = "Pinnacle Concepts Limited | Furnishing Your Dreams";

},[]);

  return (
    <>
    <section className='home-slider' >
      <CarouselFade></CarouselFade>
    </section>
    <section className='section-cover bg-cover'>
        <div className='content-container content-container-a content-container-b'>
           
          <div className='circle1'>
          </div>
          <div className='circle2'>
          </div>
            <div className='home-info'>
          <h2 className="home-h2">Who We Are</h2>
          <div className='line1'>
                    <div className='line2'></div>
          </div>

                 <p className='home-p'><strong>Pinnacle Concepts Limited</strong> brings you great furniture for your office and home. 
                 We are one of the leading furniture suppliers in Uganda and we are here to furnish your dreams. We have the furniture you need! 
                 {/* So browse our vast collection of furniture such as chairs, sofas, work stations, coffee tables, beds, dining tables and so much more. One thing you can be sure of is top of the line quality. */}

</p>

<p className='home-p'>We are composed of people from all parts of the region, giving us a wonderful breadth of cultural backgrounds, depth of talent and human potential.  We aim to build on the diversity of our people and dedicate ourselves to achieving a work environment that encourages and values the contributions of each person.</p>

           </div>
           <div className='bubu-logo-cover'>
                   <a href="https://www.bubuexpo.com"  target='_blank'>  <img src='./images/204.jpg' className='bubu-logo'></img></a>
           </div>
        </div>

    </section>
    {/* <section className='section-cover bg-cover'>
        <div className='content-container content-container-a'>
          <div className='circle1'>
          </div>
          <div className='circle2'>
          </div>
          <h2 className="home-h2">Who We Are</h2>
          <div className='line1'>
                    <div className='line2'></div>
          </div>

                 <p className='home-p'><strong>Pinnacle Concepts Limited</strong> brings you great furniture for office or home. We’ve got what you need here! On our website, we have made it so stress-free by categorizing each and every furniture section to make your browsing easy and less time consuming. So browse our vast collection of furniture such as chairs, sofas, work stations, coffee tables, beds, dining tables and so much more. One thing you can be sure of is top of the line quality.
</p>

<p className='home-p'>We are composed of people from all parts of the region, giving us a wonderful breadth of cultural backgrounds, depth of talent and human potential.  We aim to build on the diversity of our people and dedicate ourselves to achieving a work environment that encourages and values the contributions of each person.</p>

 
        </div>

    </section> */}
     <section className="catergories">
      <h2 className="home-h2 home-h2-a">Our Products</h2>
                 <div className="catergories-container">
                           <article className="category">
                             <div className="category-image">
                              <OfficeFurniture></OfficeFurniture>
                                    {/* <img src="./images/4.jpg" alt="Image" className="category-photo"/> */}
                             </div>

                             <div className="category-content">
                                    <h3 className="category-h3">OFFICE FURNITURE</h3> 
                                    <p className="category-p">Quality Office Furniture at affordable prices.</p>   
                                    
                                    {/* <a href="store.html" className="category-link">See More</a> */}
                                    <Link to='office-furniture' className="category-link" onClick={scrollFunc}>See Details</Link>

                            </div>


                           </article>

                           <article className="category">
                            <div className="category-image">
                              <HomeFurniture></HomeFurniture>
                                   {/* <img src="./images/12.jpg" alt="Image" className="category-photo"/> */}
                                  
                            </div>

                            <div className="category-content">
                                   <h3 className="category-h3">HOME FURNITURE</h3> 
                                   <p className="category-p">Beautiful and classy Home Furniture at affordable prices.</p>   
                                   
                                   {/* <a href="store.html" className="category-link">See More</a> */}
 <Link to='home-furniture' className="category-link" onClick={scrollFunc}>See Details</Link>
                           </div>


                          </article>

                          <article className="category">
                            <div className="category-image">
                               <CurtainsBlinds></CurtainsBlinds>
                                   {/* <img src="./images/40.jpg" alt="Image" className="category-photo"/> */}
                            </div>

                            <div className="category-content">
                                   <h3 className="category-h3">CURTAINS & BLINDS</h3> 
                                   <p className="category-p">Quality Curtains and Blinds at affordable prices.</p>   
                                   
                                  <Link to='curtains-blinds' className="category-link" onClick={scrollFunc}>See Details</Link>

                           </div>


                          </article>

                          <article className="category">
                           <div className="category-image">
                            <DecorAccessories></DecorAccessories>
                                  {/* <img src="./images/17.jpg" alt="Image" className="category-photo"/> */}
                           </div>

                           <div className="category-content">
                                  <h3 className="category-h3">DÉCOR & ACCESSORIES</h3> 
                                  <p className="category-p">Beautiful and amazing Décor and Accessories are here.</p>   
                                   <Link to='decor-accessories' className="category-link" onClick={scrollFunc}>See Details</Link>
                                  {/* <a href="decor-accessories" className="category-link">See Details</a> */}

                          </div>


                         </article>


                 </div>
        </section>
  <section className='home-quote' style={{ backgroundImage: `linear-gradient(rgba(1, 24, 78, 0.811), rgba(0, 0, 0, 0.811)), url("./images/4.jpg")`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundAttachment:'fixed',
    padding:'3rem 0'

    }}>
          <div className='quote-content'>
            {/* <h2 className='quote-h3'>Our Aim</h2>
            <div className='pledge-line'></div> */}
         <p> 
 We strive for continuous improvement and we are creative and single-minded in our drive to be a company of distinction for the sustained well-being of our shareholders and employees as well as the nation.
</p>
<p> 
 We pledge to be guided by the highest standards of integrity in all that we do, because we expect great things from each and every member of Pinnacle Concepts team.
</p>
        </div>
    </section>
    
 {/* <section className='section-cover section-cover1'>
       <div className='content-container goals-container'>
           <article className='goals-text'>
              <h2 className='home-h2 home-service-h2 goals-h2'>Our Scope</h2>
              <div className='line1'></div>
              <div className='goals-list goals-list1'>
                     <div className='goal'>
                         <span className='goal-icon'>
                          <FaArrowAltCircleRight></FaArrowAltCircleRight>
                          </span>
                          <p className='goal-description'>More and more of our clients are turning to us for our distinctive ability to implement
innovative project management techniques and to serve as a reliable provider of
knowledge-driven solutions for their complex construction and Supply projects.
</p>
                     </div>

                     <div className='goal'>
                         <span className='goal-icon'>
                          <FaArrowAltCircleRight></FaArrowAltCircleRight>
                          </span>
                          <p className='goal-description goal-description1'>We with our pool of professionals, our scope of services is broadly defined under four categories. These include field studies (baseline studies and monitoring and evaluation), research (proposal development, questionnaire design, data collection, data analysis, report writing and dissemination of results, data management (database design, database maintenance, readiness assessment and development), capacity building (trainings in research, data management, field studies) and tax advisory.
                 <br></br> <br></br> <strong>The Eight Investments Company Limited</strong> focuses on small and medium sized enterprises. Our economy is characterized by the small and medium-sized businesses that are major force behind the creation of employment and growth. Through our value statement, we are very certain that this cohort of enterprises would greatly benefit from our range of services.</p>
                     </div>

<Link to="who-we-are" className='home-btn' onClick={scrollFunc}>
         More About Us
         </Link>
                </div>
                   

           </article>

           <article className='goals-slides'>
               <GoalsCarousel></GoalsCarousel>
               <img src='images/16.jpg' className='scope-image'></img>

           </article>




       </div>

  </section> */}

   {/* <section className='home-service-cover'>
    <h2 className='home-h2 home-service-h2'>Why Choose Us?</h2>
     <h2 className='home-h2 home-service-h2'>What Makes Us Unique?</h2>
    <div className='line1'></div>
   
    <div className='home-services-container '>
      <div className='about-img-container'>
      <img src='./images/' alt='' className='about-img'></img>
   </div>
       <div className='home-services-data'>
       <div className='home-services-info '>

        <div className='home-service-box'>
            <div className='home-service-icon-cover'>
          <span className='home-service-icon'><FaCheck></FaCheck></span>
          </div>
          <h3 className='home-service-title'>Quality</h3>
          <p>We provide quality products and services and promise only what we can deliver and deliver on what we promise.</p>

        </div>
       
        <div className='home-service-box'>
            <div className='home-service-icon-cover'>
          <span className='home-service-icon'><FaFileAlt></FaFileAlt></span>
          </div>
          <h3 className='home-service-title'>Professionalism</h3>
          <p>No compromise to professional standards, committed to whatever decisions we take and accept the consequences.</p>

        </div>
        <div className='home-service-box'>
          <div className='home-service-icon-cover'>
          <span className='home-service-icon'><FaHandshake></FaHandshake></span>
          </div>
          <h3 className='home-service-title'>Partnership</h3>
          <p>We engage with responsible business partners and suppliers.</p>
        </div>
<div className='home-service-box'>
          <div className='home-service-icon-cover'>
          <span className='home-service-icon'><FaCalendarPlus></FaCalendarPlus></span>
          </div>
          <h3 className='home-service-title'>Durability</h3>
          <p>
           We are a market leader for durable and cost effective medical equipment in Uganda.
            </p>
        </div>

 <div className='home-service-box'>
           <div className='home-service-icon-cover'>
          <span className='home-service-icon'><FaCertificate></FaCertificate>  </span>
          </div>
          <h3 className='home-service-title'>Trust</h3>
          <p>We have a proven track record with large database of corporate clients.</p>
        </div>

    </div>
        <Link to="services" className='home-btn'>
         Our Services
         </Link>


        </div>


    </div>



  </section> */}
   
    
  
    </>
  )
}

export default Home