import Carousel from 'react-bootstrap/Carousel';
const Tool1 = () => {
  return (
    <Carousel indicators={false} className='services-slides tools-slides'>
         <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/108.jpeg"
          alt="First slide"
        />
      </Carousel.Item>
        
        
         <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/104.jpeg"
          alt="First slide"
        />
      </Carousel.Item>
        
        
        <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/103.jpeg"
          alt="First slide"
        />
      </Carousel.Item>
       
       <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/101.jpeg"
          alt="First slide"
        />
      </Carousel.Item>
      
      
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/3.jpg"
          alt="First slide"
        />
      </Carousel.Item>

      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/4.jpg"
          alt="First slide"
        />
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/5.jpg"
          alt="First slide"
        />
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/7.jpg"
          alt="First slide"
        />
      </Carousel.Item>
      
       <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/25.jpg"
          alt="First slide"
        />
      </Carousel.Item>
       <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/41.jpg"
          alt="First slide"
        />
      </Carousel.Item>
       <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/43.jpg"
          alt="First slide"
        />
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/44.jpg"
          alt="First slide"
        />
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/56.jpg"
          alt="First slide"
        />
      </Carousel.Item>
       <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/61.jpg"
          alt="First slide"
        />
      </Carousel.Item>
       <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/62.jpg"
          alt="First slide"
        />
      </Carousel.Item>
       <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/63.jpg"
          alt="First slide"
        />
      </Carousel.Item>
       <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/64.jpg"
          alt="First slide"
        />
      </Carousel.Item>
       <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/65.jpg"
          alt="First slide"
        />
      </Carousel.Item>
        <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/66.jpg"
          alt="First slide"
        />
      </Carousel.Item>
        <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/67.jpg"
          alt="First slide"
        />
      </Carousel.Item>
        <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/68.jpg"
          alt="First slide"
        />
      </Carousel.Item>
        <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/69.jpg"
          alt="First slide"
        />
      </Carousel.Item>
        <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/70.jpg"
          alt="First slide"
        />
      </Carousel.Item>
       <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/92.jpg"
          alt="First slide"
        />
      </Carousel.Item>
       <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/93.jpg"
          alt="First slide"
        />
      </Carousel.Item>
       <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/94.jpg"
          alt="First slide"
        />
      </Carousel.Item>
       <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/95.jpg"
          alt="First slide"
        />
      </Carousel.Item>
       <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/96.jpg"
          alt="First slide"
        />
      </Carousel.Item>
       <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/97.jpg"
          alt="First slide"
        />
      </Carousel.Item>
       


    </Carousel>
  )
}
export default Tool1