import React, {useEffect} from 'react'
import VisionCarousel from '../components/Vision Carousel';
import MissionCarousel from '../components/Mission Carousel';
// import ValuesAccordion from '../components/ValuesAccordion';
import {FaHandshake,FaCheck,FaCertificate, FaUsers} from 'react-icons/fa';
import {BsFillFileBarGraphFill} from 'react-icons/bs';
import CoreValues from '../components/CoreValues';
const WhoWeAre = () => {

useEffect(()=>{
document.title = "Environmental Policy | Pinnacle Concepts Limited";

},[]);


  return (
    <>

     
    <section className='section-cover who-we-are-cover' >
      <h2 className="home-h2 wwa-h2">Environmental Policy</h2>
      {/* <p className='about-intro-p'></p> */}
          <div className='content-container vm-container'>
                 <div className='vision-mission-content'>
                    

                    <article className='vision-mission-info vision-mission-info-a'>
                      <p>
                        Pinnacle Concepts Ltd Ltd recognises that it has a responsibility to the environment beyond legal and regulatory requirements. Pinnacle Concepts Ltd are committed to reducing our environmental impact and continually improving our environmental performance as an integral part of our business strategy and operating methods. Pinnacle Concepts Ltd will encourage customers, suppliers, and other stakeholders to do the same, and work with us to make a positive contribution to our work and environment through careful consideration of the materials and practices that we use in making furniture.
                      </p>
                      <h3 className='title-h3 title-h3-a'>Responsibility</h3>
                      <p>All Pinnacle Concepts Ltd employees have a responsibility in their own work areas to ensure that the aims and objectives of this policy are met.</p>
<h3 className='title-h3 title-h3-a'>Policy Aims</h3>
<p>Pinnacle Concepts Ltd endeavour to:</p>
<ul>
   <li>Comply with all relevant regulatory requirements.</li>
<li>Continually improve and monitor environmental performance.</li>
<li>Continually Improve and reduce environmental impacts.</li>
<li>Incorporate environmental factors into business decisions.</li>
<li>Increase employee awareness and training.</li>

</ul>
<h3 className='title-h3 title-h3-a'>Timber Sourcing and Policy</h3>
<p>
    Pinnacle Concepts Ltd take great care to only use timber that is ethically grown and sourced. The problems with sourcing have mainly been associated with tropical timbers, which we avoid as manufacturers as we believe that very few of the schemes can adequately cover these kinds of timbers from regions where deforestation is the major environmental issue. Pinnacle Concepts Ltd do not use suppliers who cannot certify their timber.
</p>
<p>Pinnacle Concepts Ltd has engaged in planting of forests both environmental protection and future production use. With over 500 acres already planted in our forests located in (kyalubanga) Nakasongola, (kitooda) Bugiri and (Rumuli) Jinja district, we are concerned on the environmental impact of our operations and do encourage all our stake holders to do the same.</p>
<h3 className='title-h3 title-h3-a'>Recycling</h3>
<p>Pinnacle Concepts Ltd will:</p>
<ul>
    <li>Reuse and recycle materials wherever possible.</li>
<li>Follow the specific guidelines of our recycling policy.</li>
<li>At customer’s premises, unless a facility exists on site, remove waste for recycling.</li>

</ul>
<h3 className='title-h3 title-h3-a'>Energy and water</h3>
<p>Pinnacle Concepts Ltd will seek to:</p>
<ul>
   <li>Switch off lights and electrical equipment when not in use.</li>
<li>Adjust heating with energy consumption in mind.</li>

</ul>
<h3 className='title-h3 title-h3-a'>Office and cleaning supplies</h3>
<p>Pinnacle Concepts Ltd will:</p>
<ul>
    <li>Favour more environmentally friendly and efficient products wherever possible.</li>
<li>Use licensed and appropriate organisations to dispose of waste.</li>

</ul>
<h3 className='title-h3 title-h3-a'>Monitoring and improvement</h3>
<p>Pinnacle Concepts Ltd will:</p>
<ul>
    <li>Comply with all relevant regulatory requirements.</li>
<li>Continually improve and monitor environmental performance.</li>
<li>Continually improve and reduce environmental impacts.</li>
<li>Incorporate environmental factors into business decisions.</li>
<li>Increase employee awareness through training.</li>
<li>Review this policy and any related business issues at monthly management meetings.</li>

</ul>
<h3 className='title-h3 title-h3-a'>Culture</h3>
<p>Pinnacle Concepts Ltd will:</p>
<ul>
    <li>Update this policy at least bi-annually in consultation with staff and other stakeholders where necessary.</li>
<li>Provide staff with relevant environmental training.</li>
<li>Work with suppliers, contractors and subcontractors to improve their environmental performance.</li>

</ul>
<h3 className='title-h3 title-h3-a'>Future Plans</h3>
<p>Pinnacle Concepts Ltd is currently reviewing how to:</p>
<ul>
   <li>Source electricity from green energy, and use it more economically.</li>
<li>Implement solar panels on appropriate industrial building roofs.</li>
<li>Encourage more staff to share transport or cycle to work.</li>
<li>Economise on deliveries and customer visits wherever possible.</li>
<li>Reduce material use and wastage within the factory.</li>
<li>Increase the level of recycling, including paper, packaging, plastic and metal waste.</li>
<li>Reduce overspray and lacquer use within the factory.</li>

</ul>


                    </article>

                    


                 </div>

          </div>
    </section>
 
    

    </>
  )
}

export default WhoWeAre