
import React, {useEffect, useState} from 'react';

import {FaArrowRight, FaTimes} from 'react-icons/fa';



const Team = () => {
    useEffect(()=>{
document.title = "Office Desks/Tables | Pinnacle Concepts Limited";
},[]);


  return (
    <section className='section-cover team-cover equip-cover' >
         <div className='content-container products-container'>
          
          <h2 className="home-h2 furniture-h2">Office Desks/Tables</h2>
             {/* <p className='about-intro-p'>Exotic Furniture</p> */}
         
            <div className='services-container-new'>
              
        
              <article className='single-service-new'>
    
              <div className='tools-slides-cover'>
                {/* <Tool1></Tool1> */}
                <img src='./images/104.jpeg' class='item-image'></img>
              </div>
            <h3 className='skill-h3 service-h3-new'>PC/OF/ODT/001</h3>
          </article>
           <article className='single-service-new'>
              <div className='tools-slides-cover'>
                {/* <Tool3></Tool3> */}
                <img src='./images/274.jpg' class='item-image'></img>
              </div>
            <h3 className='skill-h3 service-h3-new'>PC/OF/ODT/002</h3>        
          </article>
           <article className='single-service-new'>
              <div className='tools-slides-cover'>
                {/* <Tool3></Tool3> */}
                <img src='./images/275.jpg' class='item-image'></img>
              </div>
            <h3 className='skill-h3 service-h3-new'>PC/OF/ODT/003</h3>        
          </article>
           <article className='single-service-new'>
              <div className='tools-slides-cover'>
                {/* <Tool3></Tool3> */}
                <img src='./images/276.jpg' class='item-image'></img>
              </div>
            <h3 className='skill-h3 service-h3-new'>PC/OF/ODT/004</h3>        
          </article>
           <article className='single-service-new'>
              <div className='tools-slides-cover'>
                {/* <Tool3></Tool3> */}
                <img src='./images/277.jpg' class='item-image'></img>
              </div>
            <h3 className='skill-h3 service-h3-new'>PC/OF/ODT/005</h3>        
          </article>
           <article className='single-service-new'>
              <div className='tools-slides-cover'>
                {/* <Tool3></Tool3> */}
                <img src='./images/278.jpg' class='item-image'></img>
              </div>
            <h3 className='skill-h3 service-h3-new'>PC/OF/ODT/006</h3>        
          </article>
           <article className='single-service-new'>
              <div className='tools-slides-cover'>
                {/* <Tool3></Tool3> */}
                <img src='./images/279.jpg' class='item-image'></img>
              </div>
            <h3 className='skill-h3 service-h3-new'>PC/OF/ODT/007</h3>        
          </article>
           <article className='single-service-new'>
              <div className='tools-slides-cover'>
                {/* <Tool3></Tool3> */}
                <img src='./images/280.jpg' class='item-image'></img>
              </div>
            <h3 className='skill-h3 service-h3-new'>PC/OF/ODT/008</h3>        
          </article>
           <article className='single-service-new'>
              <div className='tools-slides-cover'>
                {/* <Tool3></Tool3> */}
                <img src='./images/281.jpg' class='item-image'></img>
              </div>
            <h3 className='skill-h3 service-h3-new'>PC/OF/ODT/009</h3>        
          </article>
           <article className='single-service-new'>
              <div className='tools-slides-cover'>
                {/* <Tool3></Tool3> */}
                <img src='./images/282.jpg' class='item-image'></img>
              </div>
            <h3 className='skill-h3 service-h3-new'>PC/OF/ODT/010</h3>        
          </article>
           <article className='single-service-new'>
              <div className='tools-slides-cover'>
                {/* <Tool3></Tool3> */}
                <img src='./images/283.jpg' class='item-image'></img>
              </div>
            <h3 className='skill-h3 service-h3-new'>PC/OF/ODT/011</h3>        
          </article>
           <article className='single-service-new'>
              <div className='tools-slides-cover'>
                {/* <Tool3></Tool3> */}
                <img src='./images/284.jpg' class='item-image'></img>
              </div>
            <h3 className='skill-h3 service-h3-new'>PC/OF/ODT/012</h3>        
          </article>
          <article className='single-service-new'>
              <div className='tools-slides-cover'>
                {/* <Tool3></Tool3> */}
                <img src='./images/37.jpg' class='item-image'></img>
              </div>
            <h3 className='skill-h3 service-h3-new'>PC/OF/ODT/013</h3>        
          </article>
                  
           <article className='single-service-new'>
              <div className='tools-slides-cover'>
                {/* <Tool3></Tool3> */}
                <img src='./images/41.jpg' class='item-image'></img>
              </div>
            <h3 className='skill-h3 service-h3-new'>PC/OF/ODT/014</h3>        
          </article>
          <article className='single-service-new'>
              <div className='tools-slides-cover'>
                {/* <Tool3></Tool3> */}
                <img src='./images/291.jpg' class='item-image'></img>
              </div>
            <h3 className='skill-h3 service-h3-new'>PC/OF/ODT/015</h3>        
          </article>
          <article className='single-service-new'>
              <div className='tools-slides-cover'>
                {/* <Tool3></Tool3> */}
                <img src='./images/292.jpg' class='item-image'></img>
              </div>
            <h3 className='skill-h3 service-h3-new'>PC/OF/ODT/016</h3>        
          </article>
          <article className='single-service-new'>
              <div className='tools-slides-cover'>
                {/* <Tool3></Tool3> */}
                <img src='./images/293.jpg' class='item-image'></img>
              </div>
            <h3 className='skill-h3 service-h3-new'>PC/OF/ODT/017</h3>        
          </article>
          <article className='single-service-new'>
              <div className='tools-slides-cover'>
                {/* <Tool3></Tool3> */}
                <img src='./images/294.jpg' class='item-image'></img>
              </div>
            <h3 className='skill-h3 service-h3-new'>PC/OF/ODT/018</h3>        
          </article>
          <article className='single-service-new'>
              <div className='tools-slides-cover'>
                {/* <Tool3></Tool3> */}
                <img src='./images/290.jpg' class='item-image'></img>
              </div>
            <h3 className='skill-h3 service-h3-new'>PC/OF/ODT/019</h3>        
          </article>
          <article className='single-service-new'>
              <div className='tools-slides-cover'>
                {/* <Tool3></Tool3> */}
                <img src='./images/305.jpg' class='item-image'></img>
              </div>
            <h3 className='skill-h3 service-h3-new'>PC/OF/ODT/020</h3>        
          </article>
          <article className='single-service-new'>
              <div className='tools-slides-cover'>
                {/* <Tool3></Tool3> */}
                <img src='./images/306.jpg' class='item-image'></img>
              </div>
            <h3 className='skill-h3 service-h3-new'>PC/OF/ODT/021</h3>        
          </article>
          <article className='single-service-new'>
              <div className='tools-slides-cover'>
                {/* <Tool3></Tool3> */}
                <img src='./images/307.jpg' class='item-image'></img>
              </div>
            <h3 className='skill-h3 service-h3-new'>PC/OF/ODT/022</h3>        
          </article>
          <article className='single-service-new'>
              <div className='tools-slides-cover'>
                {/* <Tool3></Tool3> */}
                <img src='./images/308.jpg' class='item-image'></img>
              </div>
            <h3 className='skill-h3 service-h3-new'>PC/OF/ODT/023</h3>        
          </article>
          <article className='single-service-new'>
              <div className='tools-slides-cover'>
                {/* <Tool3></Tool3> */}
                <img src='./images/309.jpg' class='item-image'></img>
              </div>
            <h3 className='skill-h3 service-h3-new'>PC/OF/ODT/024</h3>        
          </article>
          <article className='single-service-new'>
              <div className='tools-slides-cover'>
                {/* <Tool3></Tool3> */}
                <img src='./images/310.jpg' class='item-image'></img>
              </div>
            <h3 className='skill-h3 service-h3-new'>PC/OF/ODT/025</h3>        
          </article>
          <article className='single-service-new'>
              <div className='tools-slides-cover'>
                {/* <Tool3></Tool3> */}
                <img src='./images/311.jpg' class='item-image'></img>
              </div>
            <h3 className='skill-h3 service-h3-new'>PC/OF/ODT/026</h3>        
          </article>
          <article className='single-service-new'>
              <div className='tools-slides-cover'>
                {/* <Tool3></Tool3> */}
                <img src='./images/295.jpg' class='item-image'></img>
              </div>
            <h3 className='skill-h3 service-h3-new'>PC/OF/ODT/027</h3>        
          </article>
          <article className='single-service-new'>
              <div className='tools-slides-cover'>
                {/* <Tool3></Tool3> */}
                <img src='./images/296.jpg' class='item-image'></img>
              </div>
            <h3 className='skill-h3 service-h3-new'>PC/OF/ODT/028</h3>        
          </article>
          <article className='single-service-new'>
              <div className='tools-slides-cover'>
                {/* <Tool3></Tool3> */}
                <img src='./images/25.jpg' class='item-image'></img>
              </div>
            <h3 className='skill-h3 service-h3-new'>PC/OF/ODT/029</h3>        
          </article>
           <article className='single-service-new'>
    
              <div className='tools-slides-cover'>
                {/* <Tool1></Tool1> */}
                <img src='./images/103.jpeg' class='item-image'></img>
              </div>
            <h3 className='skill-h3 service-h3-new'>PC/OF/ODT/030</h3>
          </article>
          
           <article className='single-service-new'>
              <div className='tools-slides-cover'>
                {/* <Tool3></Tool3> */}
                <img src='./images/43.jpg' class='item-image'></img>
              </div>
            <h3 className='skill-h3 service-h3-new'>PC/OF/ODT/031</h3>        
          </article>
           <article className='single-service-new'>
              <div className='tools-slides-cover'>
                {/* <Tool3></Tool3> */}
                <img src='./images/96.jpg' class='item-image'></img>
              </div>
            <h3 className='skill-h3 service-h3-new'>PC/OF/ODT/032</h3>        
          </article>
                <article className='single-service-new'>
    
              <div className='tools-slides-cover'>
                {/* <Tool1></Tool1> */}
                <img src='./images/108.jpeg' class='item-image'></img>
              </div>
            <h3 className='skill-h3 service-h3-new'>PC/OF/ODT/033</h3>
          </article>
           <article className='single-service-new'>
              <div className='tools-slides-cover'>
                {/* <Tool3></Tool3> */}
                <img src='./images/44.jpg' class='item-image'></img>
              </div>
            <h3 className='skill-h3 service-h3-new'>PC/OF/ODT/034</h3>        
          </article>
          <article className='single-service-new'>
              <div className='tools-slides-cover'>
                {/* <Tool3></Tool3> */}
                <img src='./images/27.jpg' class='item-image'></img>
              </div>
            <h3 className='skill-h3 service-h3-new'>PC/OF/ODT/035</h3>        
          </article>
          <article className='single-service-new'>
              <div className='tools-slides-cover'>
                {/* <Tool3></Tool3> */}
                <img src='./images/69.jpg' class='item-image'></img>
              </div>
            <h3 className='skill-h3 service-h3-new'>PC/OF/ODT/036</h3>        
          </article>
          <article className='single-service-new'>
              <div className='tools-slides-cover'>
                {/* <Tool3></Tool3> */}
                <img src='./images/97.jpg' class='item-image'></img>
              </div>
            <h3 className='skill-h3 service-h3-new'>PC/OF/ODT/037</h3>        
          </article>
           
           <article className='single-service-new'>
              <div className='tools-slides-cover'>
                {/* <Tool3></Tool3> */}
                <img src='./images/61.jpg' class='item-image'></img>
              </div>
            <h3 className='skill-h3 service-h3-new'>PC/OF/ODT/038</h3>        
          </article>
           <article className='single-service-new'>
              <div className='tools-slides-cover'>
                {/* <Tool3></Tool3> */}
                <img src='./images/62.jpg' class='item-image'></img>
              </div>
            <h3 className='skill-h3 service-h3-new'>PC/OF/ODT/039</h3>        
          </article>
           <article className='single-service-new'>
              <div className='tools-slides-cover'>
                {/* <Tool3></Tool3> */}
                <img src='./images/63.jpg' class='item-image'></img>
              </div>
            <h3 className='skill-h3 service-h3-new'>PC/OF/ODT/040</h3>        
          </article>
           <article className='single-service-new'>
              <div className='tools-slides-cover'>
                {/* <Tool3></Tool3> */}
                <img src='./images/64.jpg' class='item-image'></img>
              </div>
            <h3 className='skill-h3 service-h3-new'>PC/OF/ODT/041</h3>        
          </article>
           <article className='single-service-new'>
              <div className='tools-slides-cover'>
                {/* <Tool3></Tool3> */}
                <img src='./images/65.jpg' class='item-image'></img>
              </div>
            <h3 className='skill-h3 service-h3-new'>PC/OF/ODT/042</h3>        
          </article>
           <article className='single-service-new'>
              <div className='tools-slides-cover'>
                {/* <Tool3></Tool3> */}
                <img src='./images/66.jpg' class='item-image'></img>
              </div>
            <h3 className='skill-h3 service-h3-new'>PC/OF/ODT/043</h3>        
          </article>
           <article className='single-service-new'>
              <div className='tools-slides-cover'>
                {/* <Tool3></Tool3> */}
                <img src='./images/67.jpg' class='item-image'></img>
              </div>
            <h3 className='skill-h3 service-h3-new'>PC/OF/ODT/044</h3>        
          </article>
           <article className='single-service-new'>
              <div className='tools-slides-cover'>
                {/* <Tool3></Tool3> */}
                <img src='./images/68.jpg' class='item-image'></img>
              </div>
            <h3 className='skill-h3 service-h3-new'>PC/OF/ODT/045</h3>        
          </article>
           
          
           <article className='single-service-new'>
              <div className='tools-slides-cover'>
                {/* <Tool3></Tool3> */}
                <img src='./images/92.jpg' class='item-image'></img>
              </div>
            <h3 className='skill-h3 service-h3-new'>PC/OF/ODT/046</h3>        
          </article>
           <article className='single-service-new'>
              <div className='tools-slides-cover'>
                {/* <Tool3></Tool3> */}
                <img src='./images/93.jpg' class='item-image'></img>
              </div>
            <h3 className='skill-h3 service-h3-new'>PC/OF/ODT/047</h3>        
          </article>
           <article className='single-service-new'>
              <div className='tools-slides-cover'>
                {/* <Tool3></Tool3> */}
                <img src='./images/94.jpg' class='item-image'></img>
              </div>
            <h3 className='skill-h3 service-h3-new'>PC/OF/ODT/048</h3>        
          </article>
           <article className='single-service-new'>
              <div className='tools-slides-cover'>
                {/* <Tool3></Tool3> */}
                <img src='./images/95.jpg' class='item-image'></img>
              </div>
            <h3 className='skill-h3 service-h3-new'>PC/OF/ODT/049</h3>        
          </article>
          
           
           
              <article className='single-service-new'>
    
              <div className='tools-slides-cover'>
                {/* <Tool1></Tool1> */}
                <img src='./images/101.jpeg' class='item-image'></img>
              </div>
            <h3 className='skill-h3 service-h3-new'>PC/OF/ODT/050</h3>
          </article>
         
         
          
           <article className='single-service-new'>
              <div className='tools-slides-cover'>
                {/* <Tool3></Tool3> */}
                <img src='./images/7.jpg' class='item-image'></img>
              </div>
            <h3 className='skill-h3 service-h3-new'>PC/OF/ODT/051</h3>        
          </article>

          <article className='single-service-new'>
              <div className='tools-slides-cover'>
                {/* <Tool3></Tool3> */}
                <img src='./images/56.jpg' class='item-image'></img>
              </div>
            <h3 className='skill-h3 service-h3-new'>PC/OF/ODT/052</h3>        
          </article>
           <article className='single-service-new'>
              <div className='tools-slides-cover'>
                {/* <Tool3></Tool3> */}
                <img src='./images/70.jpg' class='item-image'></img>
              </div>
            <h3 className='skill-h3 service-h3-new'>PC/OF/ODT/053</h3>        
          </article>
         
         
      </div>
         </div>
    </section>
  )
}

export default Team