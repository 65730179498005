
import React, {useEffect, useState} from 'react';

import {FaArrowRight, FaTimes} from 'react-icons/fa';



const Team = () => {
    useEffect(()=>{
document.title = "Office Cabinets/Bookshelves | Pinnacle Concepts Limited";
},[]);


  return (
    <section className='section-cover team-cover equip-cover' >
         <div className='content-container products-container'>
          
          <h2 className="home-h2 furniture-h2">Office Cabinets/Bookshelves</h2>
             {/* <p className='about-intro-p'>Exotic Furniture</p> */}
         
            <div className='services-container-new'>
              <article className='single-service-new'>
    
              <div className='tools-slides-cover'>
                {/* <Tool1></Tool1> */}
                <img src='./images/285.jpg' class='item-image'></img>
              </div>
            <h3 className='skill-h3 service-h3-new'>PC/OF/OCB/001</h3>
          </article>
          <article className='single-service-new'>
    
              <div className='tools-slides-cover'>
                {/* <Tool1></Tool1> */}
                <img src='./images/286.jpg' class='item-image'></img>
              </div>
            <h3 className='skill-h3 service-h3-new'>PC/OF/OCB/002</h3>
          </article>
          <article className='single-service-new'>
    
              <div className='tools-slides-cover'>
                {/* <Tool1></Tool1> */}
                <img src='./images/287.jpg' class='item-image'></img>
              </div>
            <h3 className='skill-h3 service-h3-new'>PC/OF/OCB/003</h3>
          </article>
          <article className='single-service-new'>
    
              <div className='tools-slides-cover'>
                {/* <Tool1></Tool1> */}
                <img src='./images/288.jpg' class='item-image'></img>
              </div>
            <h3 className='skill-h3 service-h3-new'>PC/OF/OCB/004</h3>
          </article>
          <article className='single-service-new'>
    
              <div className='tools-slides-cover'>
                {/* <Tool1></Tool1> */}
                <img src='./images/289.jpg' class='item-image'></img>
              </div>
            <h3 className='skill-h3 service-h3-new'>PC/OF/OCB/005</h3>
          </article>
          <article className='single-service-new'>
    
              <div className='tools-slides-cover'>
                {/* <Tool1></Tool1> */}
                <img src='./images/297.jpg' class='item-image'></img>
              </div>
            <h3 className='skill-h3 service-h3-new'>PC/OF/OCB/006</h3>
          </article>
          <article className='single-service-new'>
    
              <div className='tools-slides-cover'>
                {/* <Tool1></Tool1> */}
                <img src='./images/298.jpg' class='item-image'></img>
              </div>
            <h3 className='skill-h3 service-h3-new'>PC/OF/OCB/007</h3>
          </article>
          <article className='single-service-new'>
    
              <div className='tools-slides-cover'>
                {/* <Tool1></Tool1> */}
                <img src='./images/299.jpg' class='item-image'></img>
              </div>
            <h3 className='skill-h3 service-h3-new'>PC/OF/OCB/008</h3>
          </article>
          <article className='single-service-new'>
    
              <div className='tools-slides-cover'>
                {/* <Tool1></Tool1> */}
                <img src='./images/300.jpg' class='item-image'></img>
              </div>
            <h3 className='skill-h3 service-h3-new'>PC/OF/OCB/009</h3>
          </article>
          <article className='single-service-new'>
    
              <div className='tools-slides-cover'>
                {/* <Tool1></Tool1> */}
                <img src='./images/301.jpg' class='item-image'></img>
              </div>
            <h3 className='skill-h3 service-h3-new'>PC/OF/OCB/010</h3>
          </article>
          <article className='single-service-new'>
    
              <div className='tools-slides-cover'>
                {/* <Tool1></Tool1> */}
                <img src='./images/302.jpg' class='item-image'></img>
              </div>
            <h3 className='skill-h3 service-h3-new'>PC/OF/OCB/011</h3>
          </article>
          <article className='single-service-new'>
    
              <div className='tools-slides-cover'>
                {/* <Tool1></Tool1> */}
                <img src='./images/303.jpg' class='item-image'></img>
              </div>
            <h3 className='skill-h3 service-h3-new'>PC/OF/OCB/012</h3>
          </article>
          <article className='single-service-new'>
    
              <div className='tools-slides-cover'>
                {/* <Tool1></Tool1> */}
                <img src='./images/304.jpg' class='item-image'></img>
              </div>
            <h3 className='skill-h3 service-h3-new'>PC/OF/OCB/013</h3>
          </article>
              <article className='single-service-new'>
    
              <div className='tools-slides-cover'>
                {/* <Tool1></Tool1> */}
                <img src='./images/245.jpeg' class='item-image'></img>
              </div>
            <h3 className='skill-h3 service-h3-new'>PC/OF/OCB/014</h3>
          </article>
          
          <article className='single-service-new'>
    
              <div className='tools-slides-cover'>
                {/* <Tool1></Tool1> */}
                <img src='./images/247.jpeg' class='item-image'></img>
              </div>
            <h3 className='skill-h3 service-h3-new'>PC/OF/OCB/015</h3>
          </article>
          <article className='single-service-new'>
    
              <div className='tools-slides-cover'>
                {/* <Tool1></Tool1> */}
                <img src='./images/248.jpg' class='item-image'></img>
              </div>
            <h3 className='skill-h3 service-h3-new'>PC/OF/OCB/016</h3>
          </article>
                <article className='single-service-new'>
    
              <div className='tools-slides-cover'>
                {/* <Tool1></Tool1> */}
                <img src='./images/114.jpg' class='item-image'></img>
              </div>
            <h3 className='skill-h3 service-h3-new'>PC/OF/OCB/017</h3>
          </article>
                <article className='single-service-new'>
    
              <div className='tools-slides-cover'>
                {/* <Tool1></Tool1> */}
                <img src='./images/115.jpg' class='item-image'></img>
              </div>
            <h3 className='skill-h3 service-h3-new'>PC/OF/OCB/018</h3>
          </article>
          <article className='single-service-new'>
    
              <div className='tools-slides-cover'>
                {/* <Tool1></Tool1> */}
                <img src='./images/116.jpg' class='item-image'></img>
              </div>
            <h3 className='skill-h3 service-h3-new'>PC/OF/OCB/019</h3>
          </article>
          <article className='single-service-new'>
         
              <div className='tools-slides-cover'>
                {/* <Tool2></Tool2> */}
                <img src='./images/117.jpg' class='item-image'></img>
              </div>
            <h3 className='skill-h3 service-h3-new'>PC/OF/OCB/020</h3>
          
          </article>
          <article className='single-service-new'>
         
              <div className='tools-slides-cover'>
                {/* <Tool2></Tool2> */}
                <img src='./images/118.jpg' class='item-image'></img>
              </div>
            <h3 className='skill-h3 service-h3-new'>PC/OF/OCB/021</h3>
          
          </article>
          <article className='single-service-new'>
         
              <div className='tools-slides-cover'>
                {/* <Tool2></Tool2> */}
                <img src='./images/119.jpg' class='item-image'></img>
              </div>
            <h3 className='skill-h3 service-h3-new'>PC/OF/OCB/022</h3>
          
          </article>
          <article className='single-service-new'>
         
              <div className='tools-slides-cover'>
                {/* <Tool2></Tool2> */}
                <img src='./images/19.jpg' class='item-image'></img>
              </div>
            <h3 className='skill-h3 service-h3-new'>PC/OF/OCB/023</h3>
          
          </article>
           <article className='single-service-new'>
         
              <div className='tools-slides-cover'>
                {/* <Tool2></Tool2> */}
                <img src='./images/267.jpg' class='item-image'></img>
              </div>
            <h3 className='skill-h3 service-h3-new'>PC/OF/OCB/024</h3>
          
          </article>
        
      </div>
         </div>
    </section>
  )
}

export default Team