
import React, {useEffect, useState} from 'react';

import {FaArrowRight, FaTimes} from 'react-icons/fa';



const Team = () => {
    useEffect(()=>{
document.title = "Center Tables | Pinnacle Concepts Limited";
},[]);


  return (
    <section className='section-cover team-cover equip-cover' >
         <div className='content-container products-container'>
          
          <h2 className="home-h2 furniture-h2">Center Tables</h2>
             {/* <p className='about-intro-p'>Exotic Furniture</p> */}
         
            <div className='services-container-new'>
                <article className='single-service-new'>
    
              <div className='tools-slides-cover'>
                {/* <Tool1></Tool1> */}
                <img src='./images/154.jpg' class='item-image'></img>
              </div>
            <h3 className='skill-h3 service-h3-new'>PC/HF/CT/001</h3>
          </article>
                <article className='single-service-new'>
    
              <div className='tools-slides-cover'>
                {/* <Tool1></Tool1> */}
                <img src='./images/155.jpg' class='item-image'></img>
              </div>
            <h3 className='skill-h3 service-h3-new'>PC/HF/CT/002</h3>
          </article>
          <article className='single-service-new'>
    
              <div className='tools-slides-cover'>
                {/* <Tool1></Tool1> */}
                <img src='./images/156.jpg' class='item-image '></img>
              </div>
            <h3 className='skill-h3 service-h3-new'>PC/HF/CT/003</h3>
          </article>
          <article className='single-service-new'>
         
              <div className='tools-slides-cover'>
                {/* <Tool2></Tool2> */}
                <img src='./images/157.jpg' class='item-image'></img>
              </div>
            <h3 className='skill-h3 service-h3-new'>PC/HF/CT/004</h3>
          
          </article>
          <article className='single-service-new'>
         
              <div className='tools-slides-cover'>
                {/* <Tool2></Tool2> */}
                <img src='./images/158.jpg' class='item-image'></img>
              </div>
            <h3 className='skill-h3 service-h3-new'>PC/HF/CT/005</h3>
          
          </article>
          <article className='single-service-new'>
         
              <div className='tools-slides-cover'>
                {/* <Tool2></Tool2> */}
                <img src='./images/159.jpg' class='item-image'></img>
              </div>
            <h3 className='skill-h3 service-h3-new'>PC/HF/CT/006</h3>
          </article>
          <article className='single-service-new'>
         
              <div className='tools-slides-cover'>
                {/* <Tool2></Tool2> */}
                <img src='./images/260.jpeg' class='item-image item-image-a'></img>
              </div>
            <h3 className='skill-h3 service-h3-new'>PC/HF/CT/007</h3>
          </article>
          <article className='single-service-new'>
         
              <div className='tools-slides-cover'>
                {/* <Tool2></Tool2> */}
                <img src='./images/261.jpg' class='item-image'></img>
              </div>
            <h3 className='skill-h3 service-h3-new'>PC/HF/CT/008</h3>
          </article>
          <article className='single-service-new'>
         
              <div className='tools-slides-cover'>
                {/* <Tool2></Tool2> */}
                <img src='./images/262.jpg' class='item-image'></img>
              </div>
            <h3 className='skill-h3 service-h3-new'>PC/HF/CT/009</h3>
          </article>

      </div>
         </div>
    </section>
  )
}

export default Team