
import React, {useEffect, useState} from 'react';

import {FaArrowRight, FaTimes} from 'react-icons/fa';



const Team = () => {
    useEffect(()=>{
document.title = "Water Dispensers | Pinnacle Concepts Limited";
},[]);


  return (
    <section className='section-cover team-cover equip-cover' >
         <div className='content-container products-container'>
          
          <h2 className="home-h2 furniture-h2">Water Dispensers</h2>
             {/* <p className='about-intro-p'>Exotic Furniture</p> */}
         
            <div className='services-container-new'>
                <article className='single-service-new'>
    
              <div className='tools-slides-cover'>
                {/* <Tool1></Tool1> */}
                <img src='./images/130.jpg' class='item-image'></img>
              </div>
            <h3 className='skill-h3 service-h3-new'>PC/OF/WD/001</h3>
          </article>
                <article className='single-service-new'>
    
              <div className='tools-slides-cover'>
                {/* <Tool1></Tool1> */}
                <img src='./images/131.png' class='item-image'></img>
              </div>
            <h3 className='skill-h3 service-h3-new'>PC/OF/WD/002</h3>
          </article>
          <article className='single-service-new'>
    
              <div className='tools-slides-cover'>
                {/* <Tool1></Tool1> */}
                <img src='./images/132.jpg' class='item-image'></img>
              </div>
            <h3 className='skill-h3 service-h3-new'>PC/OF/WD/003</h3>
          </article>
          <article className='single-service-new'>
         
              <div className='tools-slides-cover'>
                {/* <Tool2></Tool2> */}
                <img src='./images/133.jpg' class='item-image'></img>
              </div>
            <h3 className='skill-h3 service-h3-new'>PC/OF/WD/004</h3>
          
          </article>
          <article className='single-service-new'>
         
              <div className='tools-slides-cover'>
                {/* <Tool2></Tool2> */}
                <img src='./images/134.jpg' class='item-image'></img>
              </div>
            <h3 className='skill-h3 service-h3-new'>PC/OF/WD/005</h3>
          
          </article>
          <article className='single-service-new'>
         
              <div className='tools-slides-cover'>
                {/* <Tool2></Tool2> */}
                <img src='./images/135.jpg' class='item-image'></img>
              </div>
            <h3 className='skill-h3 service-h3-new'>PC/OF/WD/006</h3>
          
          </article>
        
      </div>
         </div>
    </section>
  )
}

export default Team