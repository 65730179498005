import React, {useEffect} from 'react'
import VisionCarousel from '../components/Vision Carousel';
import MissionCarousel from '../components/Mission Carousel';
// import ValuesAccordion from '../components/ValuesAccordion';
import {FaHandshake,FaCheck,FaCertificate, FaUsers} from 'react-icons/fa';
import {BsFillFileBarGraphFill} from 'react-icons/bs';
import CoreValues from '../components/CoreValues';
const WhoWeAre = () => {

useEffect(()=>{
document.title = "About Us | Pinnacle Concepts Limited";

},[]);


  return (
    <>

     
    <section className='section-cover who-we-are-cover' >
      <h2 className="home-h2 wwa-h2">About Us</h2>
      {/* <p className='about-intro-p'></p> */}
          <div className='content-container vm-container'>
                 <div className='vision-mission-content'>
                    <article className='vision-mission-slides'>
                          <VisionCarousel></VisionCarousel>

                    </article>

                    <article className='vision-mission-info'>
                      
                          <h3 className='title-h3'>Our Vision</h3>
                          
                           <div className='vm-line'></div>
                          <p>To be a world-class and global manufacturer and distributor of office and institutional supplies.</p>
                          
                          <h3 className='title-h3'>Our Mission</h3>
                          <div className='vm-line'></div>
                          <p>To produce and deliver world class quality products and provide excellent and reliable services to all our customers.</p>

                    </article>

                    


                 </div>

                 {/* <div className='vision-mission-content mission-info'>

                  <article className='vision-mission-slides'>
                         
                        <MissionCarousel></MissionCarousel>
                    </article>

                    <article className='vision-mission-info vision-mission-info1'>
                      
                          <h3 className='title-h3'>Our Core Values</h3>
                          
                           <div className='vm-line'></div>
                          <ul>
                            <li>Customer Satisfaction.</li>
                            <li>Communication.</li>
                            <li>Ethical Practices & Honesty.</li>
                            <li>Quality Products & Safe Environment.</li>
                            <li>Appreciation & Recognition of our People.</li>
                            <li>Profitable & Growing Business.</li>
                          </ul>
                          
                          

                    </article>

                    <article className='vision-mission-info '>
<h3 className='title-h3'>Core Values</h3>
<div className='vm-line'></div>
           <CoreValues></CoreValues>

                    </article>


                 </div> */}
          </div>
    </section>
   <section className='home-service-cover'>
    <h2 className='home-h2 home-service-h2'>Our Core Values</h2> 
    <div className='home-services-container '>
     
       <div className='home-services-data'>
       <div className='home-services-info '>

        <div className='home-service-box'>
            <div className='home-service-icon-cover'>
          <span className='home-service-icon'><FaHandshake></FaHandshake></span>
          </div>
          <h3 className='home-service-title'>Our Customers</h3>
          {/* <p>We provide quality products and services and promise only what we can deliver and deliver on what we promise.</p> */}
<ol>
<li>We strive to earn the confidence, respect, and loyalty of our customers. </li>
<li>We observe our customers’ work styles and listen closely to them, so that we understand their needs. </li>
<li>We help our customers grow their businesses. </li>
<li>We believe each customer’s needs are special and we collaborate with them to create and provide them solutions with high standard of quality. </li>
<li>We strive to exceed customers’ needs and expectations. </li>

</ol>
        </div>
       
        <div className='home-service-box'>
            <div className='home-service-icon-cover'>
          <span className='home-service-icon'><FaUsers></FaUsers></span>
          </div>
          <h3 className='home-service-title'>Our People</h3>
          {/* <p>No compromise to professional standards, committed to whatever decisions we take and accept the consequences.</p> */}
        <ol>	
     <li> Our people are the heart and soul of Pinnacle Concepts.</li>
<li>We aim to combine our energy, knowledge, and talents daily to produce services and goods that exceed our customers’ needs. </li>
<li>Quality of our people would be the quality of our company. </li>
<li>We help each other and learn together. </li>
<li>We respect and depend on each other.</li> 
<li>We work hard.  We work smart.  We have fun! </li>
</ol>

        </div>
        <div className='home-service-box'>
          <div className='home-service-icon-cover'>
          <span className='home-service-icon'><BsFillFileBarGraphFill></BsFillFileBarGraphFill></span>
          </div>
          <h3 className='home-service-title'>Change</h3>
          {/* <p>We engage with responsible business partners and suppliers.</p> */}
        <ol>
<li>We welcome and embrace change.  </li>
<li>Change keeps us healthy and fosters innovations. </li>
<li>Change makes us forward-looking and agile in today’s changing market. </li>
<li>We believe in the power of learning.  Everyday we learn and grow. </li>
</ol>
        </div>
<div className='home-service-box'>
          <div className='home-service-icon-cover'>
          <span className='home-service-icon'><FaCheck></FaCheck></span>
          </div>
          <h3 className='home-service-title'>Effectiveness</h3>
          {/* <p>
           We are a market leader for durable and cost effective medical equipment in Uganda.
            </p> */}
<ol>
<li>We look for smart ways to work.  Effectiveness of our people is the only way that we are able to exceed our customer’s expectations. </li>
<li>We aim to simplify our business processes, manage complexity and eliminate waste. </li>
<li>We promote the wise use and preservation of our resources. </li>
<li>We encourage leadership from our people to identify and create solutions to many opportunities that face our business. </li>
</ol>
        </div>

 <div className='home-service-box'>
           <div className='home-service-icon-cover'>
          <span className='home-service-icon'><FaCertificate></FaCertificate>  </span>
          </div>
          <h3 className='home-service-title'>Excellence</h3>
          {/* <p>We have a proven track record with large database of corporate clients.</p> */}
        <ol>
<li>Everyone talks about quality. We don’t just talk about it, we do it! </li>
<li>We design, produce and market products and services that meet the most stringent quality standards.  We aim to exceed customers’ expectations. </li>
<li>We set our objectives high and don’t underestimate the abilities of our people. </li>
<li>We continually improve all aspects of our business. </li>
<li>We create great places to work.</li> 
</ol>

        </div>

    </div>
        


        </div>


    </div>



  </section> 
    

    </>
  )
}

export default WhoWeAre