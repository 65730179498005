
import React, {useEffect, useState} from 'react';

import {FaArrowRight, FaTimes} from 'react-icons/fa';



const Team = () => {
    useEffect(()=>{
document.title = "Office/Conference Chairs | Pinnacle Concepts Limited";
},[]);


  return (
    <section className='section-cover team-cover equip-cover' >
         <div className='content-container products-container'>
          
          <h2 className="home-h2 furniture-h2">Office/Conference Chairs</h2>
             {/* <p className='about-intro-p'>Exotic Furniture</p> */}
         
            <div className='services-container-new'>
                <article className='single-service-new'>
              <div className='tools-slides-cover'>
                {/* <Tool3></Tool3> */}
                <img src='./images/236.jpg' class='item-image'></img>
              </div>
            <h3 className='skill-h3 service-h3-new'>PC/OF/OCC/001</h3>        
          </article>
          <article className='single-service-new'>
              <div className='tools-slides-cover'>
                {/* <Tool3></Tool3> */}
                <img src='./images/241.jpg' class='item-image'></img>
              </div>
            <h3 className='skill-h3 service-h3-new'>PC/OF/OCC/002</h3>        
          </article>
            
              <article className='single-service-new'>
    
              <div className='tools-slides-cover'>
                {/* <Tool1></Tool1> */}
                <img src='./images/105.jpeg' class='item-image'></img>
              </div>
            <h3 className='skill-h3 service-h3-new'>PC/OF/OCC/003</h3>
          </article>
          <article className='single-service-new'>
              <div className='tools-slides-cover'>
                {/* <Tool3></Tool3> */}
                <img src='./images/240.jpg' class='item-image'></img>
              </div>
            <h3 className='skill-h3 service-h3-new'>PC/OF/OCC/004</h3>        
          </article>
          <article className='single-service-new'>
              <div className='tools-slides-cover'>
                {/* <Tool3></Tool3> */}
                <img src='./images/237.jpg' class='item-image'></img>
              </div>
            <h3 className='skill-h3 service-h3-new'>PC/OF/OCC/005</h3>        
          </article>
        
          
           <article className='single-service-new'>
              <div className='tools-slides-cover'>
                {/* <Tool3></Tool3> */}
                <img src='./images/26.jpg' class='item-image'></img>
              </div>
            <h3 className='skill-h3 service-h3-new'>PC/OF/OCC/006</h3>        
          </article>
           
           <article className='single-service-new'>
              <div className='tools-slides-cover'>
                {/* <Tool3></Tool3> */}
                <img src='./images/30.jpg' class='item-image'></img>
              </div>
            <h3 className='skill-h3 service-h3-new'>PC/OF/OCC/007</h3>        
          </article>
           <article className='single-service-new'>
              <div className='tools-slides-cover'>
                {/* <Tool3></Tool3> */}
                <img src='./images/35.jpg' class='item-image'></img>
              </div>
            <h3 className='skill-h3 service-h3-new'>PC/OF/OCC/008</h3>        
          </article>
           <article className='single-service-new'>
              <div className='tools-slides-cover'>
                {/* <Tool3></Tool3> */}
                <img src='./images/36.jpg' class='item-image'></img>
              </div>
            <h3 className='skill-h3 service-h3-new'>PC/OF/OCC/009</h3>        
          </article>
           
           <article className='single-service-new'>
              <div className='tools-slides-cover'>
                {/* <Tool3></Tool3> */}
                <img src='./images/38.jpg' class='item-image'></img>
              </div>
            <h3 className='skill-h3 service-h3-new'>PC/OF/OCC/010</h3>        
          </article>
           <article className='single-service-new'>
              <div className='tools-slides-cover'>
                {/* <Tool3></Tool3> */}
                <img src='./images/48.jpg' class='item-image'></img>
              </div>
            <h3 className='skill-h3 service-h3-new'>PC/OF/OCC/011</h3>        
          </article>
           
           <article className='single-service-new'>
              <div className='tools-slides-cover'>
                {/* <Tool3></Tool3> */}
                <img src='./images/52.jpg' class='item-image'></img>
              </div>
            <h3 className='skill-h3 service-h3-new'>PC/OF/OCC/012</h3>        
          </article>
           <article className='single-service-new'>
              <div className='tools-slides-cover'>
                {/* <Tool3></Tool3> */}
                <img src='./images/54.jpg' class='item-image'></img>
              </div>
            <h3 className='skill-h3 service-h3-new'>PC/OF/OCC/013</h3>        
          </article>
           <article className='single-service-new'>
              <div className='tools-slides-cover'>
                {/* <Tool3></Tool3> */}
                <img src='./images/55.jpg' class='item-image'></img>
              </div>
            <h3 className='skill-h3 service-h3-new'>PC/OF/OCC/014</h3>        
          </article>
         
          
          <article className='single-service-new'>
              <div className='tools-slides-cover'>
                {/* <Tool3></Tool3> */}
                <img src='./images/238.jpg' class='item-image'></img>
              </div>
            <h3 className='skill-h3 service-h3-new'>PC/OF/OCC/015</h3>        
          </article>
          <article className='single-service-new'>
              <div className='tools-slides-cover'>
                {/* <Tool3></Tool3> */}
                <img src='./images/239.jpg' class='item-image'></img>
              </div>
            <h3 className='skill-h3 service-h3-new'>PC/OF/OCC/016</h3>        
          </article>
          
          
          <article className='single-service-new'>
              <div className='tools-slides-cover'>
                {/* <Tool3></Tool3> */}
                <img src='./images/242.jpg' class='item-image'></img>
              </div>
            <h3 className='skill-h3 service-h3-new'>PC/OF/OCC/017</h3>        
          </article>
          <article className='single-service-new'>
              <div className='tools-slides-cover'>
                {/* <Tool3></Tool3> */}
                <img src='./images/243.jpg' class='item-image'></img>
              </div>
            <h3 className='skill-h3 service-h3-new'>PC/OF/OCC/018</h3>        
          </article>
          <article className='single-service-new'>
              <div className='tools-slides-cover'>
                {/* <Tool3></Tool3> */}
                <img src='./images/244.jpg' class='item-image'></img>
              </div>
            <h3 className='skill-h3 service-h3-new'>PC/OF/OCC/019</h3>        
          </article>
          
          <article className='single-service-new'>
         
              <div className='tools-slides-cover'>
                {/* <Tool2></Tool2> */}
                <img src='./images/16.jpg' class='item-image'></img>
              </div>
            <h3 className='skill-h3 service-h3-new'>PC/OF/OCC/020</h3>
          
          </article>
        <article className='single-service-new'>
              <div className='tools-slides-cover'>
                {/* <Tool3></Tool3> */}
                <img src='./images/21.jpg' class='item-image'></img>
              </div>
            <h3 className='skill-h3 service-h3-new'>PC/OF/OCC/021</h3>        
          </article>
          <article className='single-service-new'>
    
              <div className='tools-slides-cover'>
                {/* <Tool1></Tool1> */}
                <img src='./images/47.jpg' class='item-image'></img>
              </div>
            <h3 className='skill-h3 service-h3-new'>PC/OF/OCC/022</h3>
          </article>
          
      </div>
         </div>
    </section>
  )
}

export default Team