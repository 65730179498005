import React, {useEffect} from 'react'
import VisionCarousel from '../components/Vision Carousel';
import MissionCarousel from '../components/Mission Carousel';
// import ValuesAccordion from '../components/ValuesAccordion';
import {FaHandshake,FaCheck,FaCertificate, FaUsers} from 'react-icons/fa';
import {BsFillFileBarGraphFill} from 'react-icons/bs';
import CoreValues from '../components/CoreValues';
const WhoWeAre = () => {

useEffect(()=>{
document.title = "Health & Safety Policy | Pinnacle Concepts Limited";

},[]);


  return (
    <>

     
    <section className='section-cover who-we-are-cover' >
      <h2 className="home-h2 wwa-h2">Occupational Health & Safety Policy</h2>
      {/* <p className='about-intro-p'></p> */}
          <div className='content-container vm-container'>
                 <div className='vision-mission-content'>
                    

                    <article className='vision-mission-info vision-mission-info-a'>
                      
                          <h3 className='title-h3 title-h3-a'>A: Policy Manual Introduction</h3>
                          
                           {/* <div className='vm-line'></div> */}
                          <p>Pinnacle Concepts Ltd. is situated at plot 1075, farm road Kyambogo and provides office furniture, domestic furniture sales and delivery/installation service.
This manual provides details of the health and safety system that has been adopted by Pinnacle Concepts Ltd to control health and safety as far as reasonably practicable.
</p>
                          
                          <h3 className='title-h3 title-h3-a'>B: Health and Safety Policy Statement</h3>
                          {/* <div className='vm-line'></div> */}
                          <p>Pinnacle Concepts Ltd recognises that it has a responsibility to ensure the “Health, Safety and Welfare” of all employees whilst at work.
Additionally it is the responsibility of all persons who work for Pinnacle Concepts Ltd to ensure this policy extends to all other persons who may be affected by our work activities.
In this way, each and every individual within Pinnacle Concepts Ltd has a vital and specific
role in maintaining our health and safety standard.
</p>
<p>
    Responsibility for the overall implementation, maintenance and development of our safety
management system, however, rests with the Senior Management, including the regular setting and reviewing of objectives, and the provision of adequate resources to allow those objectives to be achieved.

</p>
<p>
    It is also the responsibility of Senior Management within Pinnacle Concepts Ltd to ensure that our health and safety policy conforms to all applicable legislation.
Management recognises the importance of its staff and encourages the reporting of any hazard or risk that may adversely affect our health and safety standard.
We also recognise the importance of ensuring that staff are adequately trained, informed and supervised to fulfil their activities in a safe manner.

</p>
<p>Pinnacle Concepts Ltd is committed to a management system that demonstrates a pro-active approach through the continual improvement of our health and safety performance. This is further achieved by the regular setting and reviewing of objectives to achieve compliance to current safety legislation, and through matching our industry’s best practices.
</p>
<p>
Pinnacle Concepts Ltd also recognises the need for the identification of hazards and the
recording and reviewing of risk assessments.
The effectiveness of our health and safety policy and its implementation are subject to regular management reviews.

</p>
<h3 className='title-h3 title-h3-a'>C: Planning</h3>
<p><strong>C1: Planning for Hazard Identification, Risk Assessment and Risk Control</strong><br></br>
Ensuring that sufficient resources are made available for the safety system to be fully effective.
It is the aim of Pinnacle Concepts Ltd to first identify the extent of the risks that are posed by routine and non-routine activities undertaken as part of our own work processes.
The basis for identifying these risks is the process of hazard identification, risk assessment and risk control.
</p>
<p>
    Reviews of the risk assessment/risk control process will be completed on an annual basis unless there is a change in the activities, equipment or fabric of the building, which demand that an amendment or new assessment is to be completed.
The results of all risk assessments will be entered on the relevant Risk Assessment Forms and any remedial actions highlighted as part of the assessment, are entered onto an Action Plan.
All Action Plans identify the task to be completed, the person responsible for ensuring the task is completed and a deadline date for its completion.

</p>
<p>
  <strong>  C2: Legal and other Requirements</strong><br></br>
Many of the procedures and operations used within Pinnacle Concepts Ltd that are included
in our risk assessment process, have relevant guidance published by external agencies that will assist in complying with health and safety legislation.

</p>
<p>
  <strong> C3: Occupational Health and Safety Management Programme</strong> <br></br>
To ensure that nominated tasks are completed by designated target dates, it is the responsibility of the designated appointed person, to carry out a review of the action plans on a regular basis.

</p>
<h3 className='title-h3 title-h3-a'>D: Implementation and Operation</h3>
<p>
  <strong> D1.1: Structure and Responsibilities</strong> <br></br>
All employees and management members within Pinnacle Concepts Ltd have a responsibility for their own safety and the safety of others.

</p>
<p>
     <strong> D1.2: Employees’ Responsibilities</strong><br></br>
In accordance with Pinnacle Concepts Ltd’s Health and Safety Policy, all employees have an
individual responsibility and duty for:

</p>
<ul>
<li>Making themselves familiar with, and conforming with, our Health and Safety Policy at all
times.</li>
<li>Conforming to the Safety Rules and Safe Systems of Work Procedures at all times, including permit to work systems, inspection and testing etc.</li>
<li>Wearing appropriate safety or protective equipment, personal footwear or clothing and using
appropriate safety devices, as specified, at all times.</li>
<li>Making suggestions to improve health and safety in the company to the Management.</li>
<li>Reporting without delay all risks or hazards, including defects to machinery, unsafe practices, and “near miss” situations directly to their Manager.</li>
<li>Reporting without delay any injury suffered as the result of any accident during the course of their work to their immediate supervisor or First Aider as appropriate.</li>
<li>Ensuring that good housekeeping is maintained at all times at their work station and
surrounding area.</li>
<li> Taking reasonable care of themselves and other persons who may be affected by their actions whilst at work.</li>

</ul>
<p>
    <strong>D2: Training, Awareness and Competence</strong><br></br>
Pinnacle Concepts Ltd recognises training as a key feature in its on-going program, to ensure
an effective health and safety management system.

Training provisions are divided into three main areas:

</p>
<ul>
    <li> Induction Training - This is an internal program covering the general health and safety issues and activities of specific importance to employees.</li>
<li>Job/Task Specific - This is an internal or external program where specialist skills or knowledge is required to conduct a specific task or activity.</li>
<li>Refresher - This is an internal or external program where additional training will be provided at specified intervals for updating personnel, trained in specific tasks.</li>

</ul>
<p>
   <strong> D3: Consultation and Communication</strong><br></br>
Consultation and communication of health and safety issues is generally undertaken on an
informal basis between the management and staff.
Management recognises the importance of ensuring that we maintain an adequate safety
management system.

</p>
<p>
    The setting/reviewing of safety objectives is publicised to all members of staff to make sure that all employees within Pinnacle Concepts Ltd are involved in the development of the safety management system.
If however, more formal means of communication are required (e.g. accident/incident reports, buildings maintenance etc.), formal reporting procedures exist and employees are encouraged to report any item they feel may present a potential hazard.
In the event of any changes in the structure of Pinnacle Concepts Ltd’s safety management
system, the need to convene a formal Health and Safety Committee would be discussed at
Management Review.

</p>
<p>
   <strong> D4: Documentation And Record Management</strong><br></br>
All documentation held within Pinnacle Concepts Ltd’s Health And Safety Management
System is the responsibility of the designated appointed person.
That person is responsible for retaining the “MASTER COPY” of the document and for ensuring that necessary copies are issued to employees or any other interested parties.
Procedures exist within the health and safety manual for the issue, review and revision of all
documents and forms relevant to the health and safety management system.
Procedures also exist for controlling documents used within Pinnacle Concepts Ltd, including
the retention period and location of documentation.

</p>
<p>
   <strong> D5: Operational Control</strong><br></br>
Procedures and safe working practices are retained in the Health And Safety Manual, and have been produced to outline the operational and management controls of relevant activities within Pinnacle Concepts Ltd.
These may include: 
</p>
<ul>
   <li> Routine and Non – Routine activities or operations, that may carry a significant risk.</li>
<li>Activities/Operations that may require the assistance of external agencies (e.g. contractors).</li>
<li>Building monitoring and maintenance.</li>
<li>Equipment monitoring and maintenance.</li>

</ul>
<p>
    In the majority of instances the production of any procedures or safe working practice will
normally follow the completion of a suitable and sufficient risk assessment.

</p>
<p>
  <strong>  D6: Emergency Preparedness and Response</strong><br></br>
The nature of the work activities undertaken within Pinnacle Concepts Ltd, are such that
protracted emergency plans are not necessary and the provisions of C.O.M.A.H. (Control of
Major Accident Hazard regulations) do not apply.
However, where appropriate, procedures and safe working practices have been developed to address the potential emergency situations that could arise.

</p>
<h3 className='title-h3 title-h3-a'>E: Checking and Corrective Action</h3>
<p><strong>E1: Performance Measurement and Monitoring</strong><br></br>
The health and safety management system within Pinnacle Concepts Ltd allows for both pro- active and re-active monitoring.
Pro-active monitoring can be achieved by any of the following means: - Risk Assessments
Monthly Workplace Inspections (if required)
Periodical Fire Protection Media Inspections
Periodic Statutory Inspections (i.e. Portable Appliance Testing, Fixed Electrical Testing).
Compliance with the various Action Plans
Re-active monitoring is undertaken following serious accidents or incidents, reports of ill-health or property damage.
</p>
<p>
    It is the responsibility of the designated appointed person to review both aspects of monitoring
on a periodical basis, as reasonably practicable.
There is no measurement equipment used within Pinnacle Concepts Ltd, that affects the
performance of the health and safety management system.
Measurement of performance therefore focuses on an on-going evaluation of achieving the
safety objectives within the time-scales agreed and from review of the relevant inspection
documentation.

</p>
<p>
 <strong> E2: Accidents, Incidents, Non-conformances and Corrective and Preventive Action</strong><br></br>
Procedures exist within Pinnacle Concepts Ltd’s health and safety policy to ensure that any
variations from its policies and any uncontrolled activities, are investigated and where
appropriate, remedial actions are planned and implemented.
Accidents, Incidents and Non-conformances are divided into three main categories:
</p>
<ul>
   <li>Major Accidents/Incidents (These are always investigated and where appropriate reported to the enforcing Authorities).</li>
<li> Minor Accidents/Incidents (These are only investigated when sufficiently serious to warrant investigation).</li>
<li> Substantial variations to working practices resulting in dangerous occurrences arising (These are always investigated).</li>

</ul>
<p>
    All investigations will be conducted by the designated appointed person.
Any remedial actions resulting from the investigation will be recorded on an objective plan form
for implementation by the designated appointed person.
The need for corrective action is identified from the Inspection Documentation, or
Accident/Incident Reports.
Where remedial action is required this action is divided into specific measurable tasks and
entered onto the objective plan form.
Any corrective actions resulting from management review will be entered onto the
management action plan.
The need for preventative action is normally identified through the Risk Assessment Process; it may also be determined via the Remedial Action Plan process.
In both instances, on-going monitoring within Pinnacle Concepts Ltd assesses the suitability of the remedial actions taken and the successful implementation of these actions.
In both instances the activities are reviewed at management review on an annual basis.

</p>
<h3 className='title-h3 title-h3-a'>F: Management Review</h3>
<p>
   <strong> F1: Purpose of the Review</strong><br></br>
It is the responsibility of the designated appointed person, to ensure that annual Health and
Safety Review Meetings are conducted at least annually, and that all key personnel attend.
The review meeting determines the successful implementation of the health and safety
management system and is considered to be the main forum for the discussion of any system
objectives.

</p>


                    </article>

                    


                 </div>

          </div>
    </section>
 
    

    </>
  )
}

export default WhoWeAre