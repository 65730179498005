
import React, {useEffect, useState} from 'react';

import {FaArrowRight, FaTimes} from 'react-icons/fa';

import Tool1 from '../components/Tool1';
import Tool2 from '../components/Tool2';
import Tool3 from '../components/Tool3';
import Tool4 from '../components/Tool4';
import Tool5 from '../components/Tool5';
import Tool6 from '../components/Tool6';
import Tool7 from '../components/Tool7';
import Tool8 from '../components/Tool8';
import Tool9 from '../components/Tool9';
import Tool10 from '../components/Tool10';
import Tool11 from '../components/Tool11';

const Team = () => {

                      let [modal1, setModal1] = useState(false);
                      let [modal2, setModal2] = useState(false);
                      let [modal3, setModal3] = useState(false);
                      let [modal4, setModal4] = useState(false);
                      let [modal5, setModal5] = useState(false);
                      let [modal6, setModal6] = useState(false);
                      let [modal7, setModal7] = useState(false);
                      let [modal8, setModal8] = useState(false);
                      let [modal9, setModal9] = useState(false);
                      let [modal10, setModal10] = useState(false);
                      let [modal11, setModal11] = useState(false);
                     

useEffect(()=>{
document.title = "Our Equipment | Pinnacle Concepts Limited";
},[]);

let modalOneFunc =()=>{
  setModal1(true);
}

let modalTwoFunc =()=>{
  setModal2(true);
}

let modalThreeFunc =()=>{
  setModal3(true);
}

let modalFourFunc =()=>{
  setModal4(true);
}

let modalFiveFunc =()=>{
  setModal5(true);
}

let modalSixFunc =()=>{
  setModal6(true);
}

let modalSevenFunc =()=>{
  setModal7(true);
}

let modalEightFunc =()=>{
  setModal8(true);
}

let modalNineFunc =()=>{
  setModal9(true);
}

let modalTenFunc =()=>{
  setModal10(true);
}
let modalElevenFunc =()=>{
  setModal11(true);
}

let closeModalOne = ()=>{
setModal1(false);

}

let closeModalTwo = ()=>{
setModal2(false);
}

let closeModalThree = ()=>{
setModal3(false);
}

let closeModalFour = ()=>{
setModal4(false);
}

let closeModalFive = ()=>{
setModal5(false);
}

let closeModalSix = ()=>{
setModal6(false);
}

let closeModalSeven = ()=>{
setModal7(false);
}

let closeModalEight = ()=>{
setModal8(false);
}

let closeModalNine = ()=>{
setModal9(false);
}

let closeModalTen = ()=>{
setModal10(false);
}

let closeModalEleven = ()=>{
setModal11(false);
}


let closeAll =(e)=>{
  if(e.target.classList.contains('modal-container')){
  setModal1(false);
  setModal2(false);
  setModal3(false);
  setModal4(false);
  setModal5(false);
  setModal6(false);
  setModal7(false);
  setModal8(false);
  setModal9(false);
  setModal10(false);
  setModal11(false);
  }

}

window.addEventListener('keydown',(e)=>{
 
  if(e.key === 'Escape'){
    setModal1(false);
    setModal2(false);
    setModal3(false);
    setModal4(false);
    setModal5(false);
    setModal6(false);
    setModal7(false);
    setModal8(false);
    setModal9(false);
    setModal10(false);
    setModal11(false);
  }

})


  return (
    <section className='section-cover team-cover equip-cover' >
         <div className='content-container table-container'>
          
          <h2 className="home-h2 team-h2">Our Equipment</h2>
             <p className='about-intro-p'>Refurbished medical equipment with 1 year warranty included on sale below:</p>
         
{/* Cards Start */}
            <div className='services-container-new'>
          <article className='single-service-new'>
              {/* <div className='service-icon'>
                <BsColumnsGap></BsColumnsGap>
              </div> */}
              <div className='tools-slides-cover'>
                <Tool1></Tool1>
              </div>
            <h3 className='skill-h3 service-h3-new'>Stryker “M Series” Stretchers & Gurneys Refurbished </h3>
            <button    onClick={modalOneFunc} className='service-btn'>Read More <span>
              <FaArrowRight></FaArrowRight>
              </span></button>
          
          </article>
          <article className='single-service-new'>
            {/* <div className='service-icon'>
                <FaReact></FaReact>
              </div> */}
              <div className='tools-slides-cover'>
                <Tool2></Tool2>
              </div>
            <h3 className='skill-h3 service-h3-new'>Skytron 6500 Elite Surgical Tables </h3>
             <button  onClick={modalTwoFunc}    className='service-btn'>Read More <span>
              <FaArrowRight></FaArrowRight>
              </span></button>
          
          </article>
          <article className='single-service-new'>
            {/* <div className='service-icon'>
                <HiOutlineCode></HiOutlineCode>
              </div> */}
              <div className='tools-slides-cover'>
                <Tool3></Tool3>
              </div>
            <h3 className='skill-h3 service-h3-new'>GE Dinamap V100 Vital Sign Monitor </h3>
             <button onClick={modalThreeFunc}  className='service-btn'>Read More <span>
              <FaArrowRight></FaArrowRight>
              </span></button>
          
          </article>
          <article className='single-service-new'>
            {/* <div className='service-icon'>
                <HiOutlineCog></HiOutlineCog>
              </div> */}
              <div className='tools-slides-cover'>
                <Tool4></Tool4>
              </div>
            <h3 className='skill-h3 service-h3-new'>Portable ultrasounds and ultrasound imaging equipment </h3>
             <button onClick={modalFourFunc}    className='service-btn'>Read More <span>
              <FaArrowRight></FaArrowRight>
              </span></button>
          
          </article>

          <article className='single-service-new'>
            {/* <div className='service-icon'>
                <FaRegThumbsUp></FaRegThumbsUp>
              </div> */}
              <div className='tools-slides-cover'>
                <Tool5></Tool5>
              </div>
            <h3 className='skill-h3 service-h3-new'>Welch Allyn Wall Mounted Otoscope, Opthalmascope Boards  </h3>
             <button onClick={modalFiveFunc}  className='service-btn'>Read More <span>
              <FaArrowRight></FaArrowRight>
              </span></button>
          
          </article>
          <article className='single-service-new'>
            {/* <div className='service-icon'>
                <FaRegThumbsUp></FaRegThumbsUp>
              </div> */}
              <div className='tools-slides-cover'>
                <Tool6></Tool6>
              </div>
            <h3 className='skill-h3 service-h3-new'>Surgical Equipment </h3>
             <button onClick={modalSixFunc}  className='service-btn'>Read More <span>
              <FaArrowRight></FaArrowRight>
              </span></button>
          
          </article>
          <article className='single-service-new'>
            {/* <div className='service-icon'>
                <FaRegThumbsUp></FaRegThumbsUp>
              </div> */}
              <div className='tools-slides-cover'>
                <Tool7></Tool7>
              </div>
            <h3 className='skill-h3 service-h3-new'>Surgical Lights</h3>
             <button onClick={modalSevenFunc}  className='service-btn'>Read More <span>
              <FaArrowRight></FaArrowRight>
              </span></button>
          
          </article>

          <article className='single-service-new'>
            {/* <div className='service-icon'>
                <FaRegThumbsUp></FaRegThumbsUp>
              </div> */}
              <div className='tools-slides-cover'>
                <Tool8></Tool8>
              </div>
            <h3 className='skill-h3 service-h3-new'>Surgical Tables</h3>
             <button onClick={modalEightFunc}  className='service-btn'>Read More <span>
              <FaArrowRight></FaArrowRight>
              </span></button>
          
          </article>

          <article className='single-service-new'>
            {/* <div className='service-icon'>
                <FaRegThumbsUp></FaRegThumbsUp>
              </div> */}
              <div className='tools-slides-cover'>
                <Tool9></Tool9>
              </div>
            <h3 className='skill-h3 service-h3-new'>Doctor exam office medical equipment</h3>
             <button onClick={modalNineFunc}  className='service-btn'>Read More <span>
              <FaArrowRight></FaArrowRight>
              </span></button>
          
          </article>

          <article className='single-service-new'>
            {/* <div className='service-icon'>
                <FaRegThumbsUp></FaRegThumbsUp>
              </div> */}
              <div className='tools-slides-cover'>
                <Tool10></Tool10>
              </div>
            <h3 className='skill-h3 service-h3-new'>Medical carts/medical crash carts, ER carts</h3>
             <button onClick={modalTenFunc}  className='service-btn'>Read More <span>
              <FaArrowRight></FaArrowRight>
              </span></button>
          
          </article>
          <article className='single-service-new'>
            {/* <div className='service-icon'>
                <FaRegThumbsUp></FaRegThumbsUp>
              </div> */}
              <div className='tools-slides-cover'>
                <Tool11></Tool11>
              </div>
            <h3 className='skill-h3 service-h3-new'>Neo Natal infant equipment</h3>
             <button onClick={modalElevenFunc}  className='service-btn'>Read More <span>
              <FaArrowRight></FaArrowRight>
              </span></button>
          
          </article>
   
      </div>

      {/* Cards End */}

      {/* Modals Start */}

      <section className={`${modal1?'modal-container' :'modal-container hide-modal' }`}  onClick={closeAll}>
          <div className='modal-content'>

            <div  onClick={closeModalOne}     className='modal-close-icon'>
              <FaTimes></FaTimes>
            </div>
               <h4 className='modal-h4'>Stryker “M Series” Stretchers & Gurneys Refurbished</h4>
               <div className='underline'> </div>
                  <p className='modal-p'>Stryker “M Series” Stretchers and Gurneys Refurbished with 1 year warranty
and brand new mattress pads including Stryker 1005 model and Stryker 1015
big wheel – 5th wheel stretcher models. Popular for surgery centers, post anesthesia care, emergency departments and more.
</p>
          </div>

      </section>

       <section className={`${modal2?'modal-container' :'modal-container hide-modal' }`}  onClick={closeAll}>
          <div className='modal-content'>

            <div  onClick={closeModalTwo}     className='modal-close-icon'>
              <FaTimes></FaTimes>
            </div>
               <h4 className='modal-h4'>Skytron 6500 Elite Surgical Tables </h4>
               <div className='underline'> </div>
                  <p className='modal-p'>Skytron 6500 Elite Surgical Tables – Now only refurbished with 1 year parts warranty and new mattress pads included. These are one of the most reliable and durable surgery tables manufactured in the USA. </p>
          </div>
      </section>

      <section className={`${modal3?'modal-container' :'modal-container hide-modal' }`}  onClick={closeAll}>
          <div className='modal-content'>

            <div  onClick={closeModalThree}     className='modal-close-icon'>
              <FaTimes></FaTimes>
            </div>
               <h4 className='modal-h4'>GE Dinamap V100 Vital Sign Monitor </h4>
               <div className='underline'> </div>
                  <p className='modal-p'>GE Dinamap V100 Vital Sign Monitor Includes NIBP, SP02, Temp with new battery, new cables and 1 year warranty with for trolley cart.</p>
          </div>
      </section>

      <section className={`${modal4?'modal-container' :'modal-container hide-modal' }`}  onClick={closeAll}>
          <div className='modal-content'>

            <div  onClick={closeModalFour}     className='modal-close-icon'>
              <FaTimes></FaTimes>
            </div>
               <h4 className='modal-h4'>Portable ultrasounds and ultrasound imaging equipment </h4>
               <div className='underline'> </div>
                  <p className='modal-p'>Portable ultrasounds and ultrasound imaging equipment - including Healcerion Sonon 300L, Sonon 300C, Sonoscape A6, Sonoscape S2, Eden portable ultrasounds, Sonosite 180 Plus ultrasounds, Soundmed ultrasounds and more including NEW OB GYN ultrasound equipment with convex, linear and transvaginal probe / transducers. Cardiac color ultrasounds with cardiac probes, convex probes and 1 year warranty. Ultrasounds for muscle skeletal, vascular, abdominal studies and more. GE, Philips, Siemens ultrasound units in stock now.</p>

          </div>
      </section>

      <section className={`${modal5?'modal-container' :'modal-container hide-modal' }`}  onClick={closeAll}>
          <div className='modal-content'>

            <div  onClick={closeModalFive}     className='modal-close-icon'>
              <FaTimes></FaTimes>
            </div>
               <h4 className='modal-h4'>Welch Allyn Wall Mounted Otoscope, Opthalmascope Boards   </h4>
               <div className='underline'> </div>
                  <p className='modal-p'>Welch Allyn Wall Mounted Otoscope, Opthalmascope Boards  Welch Allyn 767 Otoscope, opthalmascope, blood pressure, specula disposable  and wall mount board.</p>
    </div>
      </section>

      <section className={`${modal6?'modal-container' :'modal-container hide-modal' }`}  onClick={closeAll}>
          <div className='modal-content'>

            <div  onClick={closeModalSix}     className='modal-close-icon'>
              <FaTimes></FaTimes>
            </div>
               <h4 className='modal-h4'>Surgical Equipment </h4>
               <div className='underline'> </div>
                  <p className='modal-p'>Surgical Equipment – “OR” / Operating room tables, surgical lights, Anesthesia machines, defibrillators, ventilators, infusion pumps, electro surgical generators, crash carts, procedure chairs, endoscopy and laparoscopic equipment, stainless steel utensil trays, back tables, kick buckets, IV poles and more. </p>
    
    </div>
      </section>

      <section className={`${modal7?'modal-container' :'modal-container hide-modal' }`}  onClick={closeAll}>
          <div className='modal-content'>

            <div  onClick={closeModalSeven}     className='modal-close-icon'>
              <FaTimes></FaTimes>
            </div>
               <h4 className='modal-h4'>Surgical Lights</h4>
               <div className='underline'> </div>
                  <p className='modal-p'>Operating Room / Surgical Equipment “OR” operating room / surgical lights – patient ready refurbished Berchtold D 660 / Berchtold D650 or lights – dual head. ALM surgical light sets – dual head,Skytron Infinity OR light sets.</p>
    
    </div>
      </section>

         <section className={`${modal8?'modal-container' :'modal-container hide-modal' }`}  onClick={closeAll}>
          <div className='modal-content'>

            <div  onClick={closeModalEight}     className='modal-close-icon'>
              <FaTimes></FaTimes>
            </div>
               <h4 className='modal-h4'>Surgical Tables</h4>
               <div className='underline'> </div>
                  <p className='modal-p'>Operating Room “OR” / Surgical tables – patient ready – refurbished biomed certified Skytron 6001 operating room table,Skytron 6500 Elite OR tables,Skytron 6500 Elite “Hercules” bariatric OR table,Steris – Amsco 3080 and 3085 SP Operating room table,AMSCO 2080 “RC” electric surgical table,AMSCO 2080 “M” manual surgical table,Berchtold 810 operating room tables.</p>
    
    </div>
      </section>

       <section className={`${modal9?'modal-container' :'modal-container hide-modal' }`}  onClick={closeAll}>
          <div className='modal-content'>

            <div  onClick={closeModalNine}     className='modal-close-icon'>
              <FaTimes></FaTimes>
            </div>
               <h4 className='modal-h4'>Doctor exam office medical equipment </h4>
               <div className='underline'> </div>
                  <p className='modal-p'>Exam Tables – Exam Room Equipment including: Ritter and Midmark exam tables – Ritter 204, Ritter 108, Midmark 104, Midmark 304, Midmark 404 exam tables, 604 exam table, and power exam tables including the Ritter 222, Ritter 223, Midmark 305, Midmark 405, Midmark 622, 623 along with some Brewer and UMF exam table models.</p>
                   <p className='modal-p'>Other doctor exam room equipment includes mini stools, exam room lights, mayo stands, Welch Allyn otoscopes, ophthalmoscopes, wall mounted blood pressure, portable temperature gauges, Ritter M9 and M11 sterilizer /autoclaves and other exam room equipment.</p>
                   <p className='modal-p'>Power Procedure Chairs and Electric Exam Chairs including Mimark 75L, Midmark 111, Midmark 75 Evolution, Midmark 411 with pre-programmable positioning, Midmark 119, Midmark 417 Podiatry, chair, models and more. </p>
    </div>
      </section>
      <section className={`${modal10?'modal-container' :'modal-container hide-modal' }`}  onClick={closeAll}>
          <div className='modal-content'>

            <div  onClick={closeModalTen}     className='modal-close-icon'>
              <FaTimes></FaTimes>
            </div>
               <h4 className='modal-h4'>Medical carts/medical crash carts, ER carts</h4>
               <div className='underline'> </div>
                  <p className='modal-p'>Medical carts/medical crash carts, ER carts: IV poles, mayo stands, back tables, stainless steel operating room equipment. Hill Rom and Stryker Hospital Beds sold used-refurbished and reconditioned</p>
                  
    </div>
      </section>

      <section className={`${modal11?'modal-container' :'modal-container hide-modal' }`}  onClick={closeAll}>
          <div className='modal-content'>

            <div  onClick={closeModalEleven}     className='modal-close-icon'>
              <FaTimes></FaTimes>
            </div>
               <h4 className='modal-h4'>Neo Natal infant equipment</h4>
               <div className='underline'> </div>
                  <p className='modal-p'>Neo Natal infant equipment: Drager Isolette® Infant Incubator (Models C2000 and C2000e) Refurbished – Drager Resuscitaire Infant Warmer baby cribs, infant scales, infant phototherapy lights and more. Looking for infant, NEO Natal, NICU medical equipment.</p>
                   
    </div>
      </section>

      {/* Modals End */}

         </div>
    </section>
  )
}

export default Team