import Carousel from 'react-bootstrap/Carousel';
const Tool1 = () => {
  return (
    <Carousel indicators={false} className='services-slides tools-slides'>
    

      {/* <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/141.jpg"
          alt="First slide"
        />
      </Carousel.Item> */}

       <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/142.jpg"
          alt="First slide"
        />
      </Carousel.Item>

       <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/143.jpg"
          alt="First slide"
        />
      </Carousel.Item>

      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/144.jpg"
          alt="First slide"
        />
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/145.jpg"
          alt="First slide"
        />
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/146.jpg"
          alt="First slide"
        />
      </Carousel.Item>

      

    </Carousel>
  )
}
export default Tool1