
import React, {useEffect, useState} from 'react';

import {FaArrowRight, FaTimes} from 'react-icons/fa';



const Team = () => {
    useEffect(()=>{
document.title = "Kitchen Cabinets | Pinnacle Concepts Limited";
},[]);


  return (
    <section className='section-cover team-cover equip-cover' >
         <div className='content-container products-container'>
          
          <h2 className="home-h2 furniture-h2">Kitchen Cabinets</h2>
             {/* <p className='about-intro-p'>Exotic Furniture</p> */}
         
            <div className='services-container-new'>
                <article className='single-service-new'>
    
              <div className='tools-slides-cover'>
                {/* <Tool1></Tool1> */}
                <img src='./images/166.jpg' class='item-image '></img>
              </div>
            <h3 className='skill-h3 service-h3-new'>PC/HF/KC/001</h3>
          </article>
                <article className='single-service-new'>
    
              <div className='tools-slides-cover'>
                {/* <Tool1></Tool1> */}
                <img src='./images/167.jpg' class='item-image '></img>
              </div>
            <h3 className='skill-h3 service-h3-new'>PC/HF/KC/002</h3>
          </article>
          <article className='single-service-new'>
    
              <div className='tools-slides-cover'>
                {/* <Tool1></Tool1> */}
                <img src='./images/168.jpg' class='item-image item-image-a'></img>
              </div>
            <h3 className='skill-h3 service-h3-new'>PC/HF/KC/003</h3>
          </article>
          <article className='single-service-new'>
         
              <div className='tools-slides-cover'>
                {/* <Tool2></Tool2> */}
                <img src='./images/169.jpg' class='item-image'></img>
              </div>
            <h3 className='skill-h3 service-h3-new'>PC/HF/KC/004</h3>
          
          </article>
          <article className='single-service-new'>
         
              <div className='tools-slides-cover'>
                {/* <Tool2></Tool2> */}
                <img src='./images/170.jpg' class='item-image'></img>
              </div>
            <h3 className='skill-h3 service-h3-new'>PC/HF/KC/005</h3>
          
          </article>
          <article className='single-service-new'>
         
              <div className='tools-slides-cover'>
                {/* <Tool2></Tool2> */}
                <img src='./images/171.jpg' class='item-image'></img>
              </div>
            <h3 className='skill-h3 service-h3-new'>PC/HF/KC/006</h3>
          </article>
      </div>
         </div>
    </section>
  )
}

export default Team