
import React, {useEffect} from 'react';

import {FaArrowRight, FaTimes} from 'react-icons/fa';



const Team = () => {
    useEffect(()=>{
document.title = "Sofa Sets | Pinnacle Concepts Limited";
},[]);


  return (
    <section className='section-cover team-cover equip-cover' >
         <div className='content-container products-container'>
          
          <h2 className="home-h2 furniture-h2">Sofa Sets</h2>
             {/* <p className='about-intro-p'>Exotic Furniture</p> */}
         
            <div className='services-container-new'>
              <article className='single-service-new'>
    
              <div className='tools-slides-cover'>
                {/* <Tool1></Tool1> */}
                <img src='./images/249.jpg' class='item-image '></img>
              </div>
            <h3 className='skill-h3 service-h3-new'>PC/HF/SS/001</h3>
          </article>
          <article className='single-service-new'>
    
              <div className='tools-slides-cover'>
                {/* <Tool1></Tool1> */}
                <img src='./images/250.jpg' class='item-image '></img>
              </div>
            <h3 className='skill-h3 service-h3-new'>PC/HF/SS/002</h3>
          </article>
          <article className='single-service-new'>
    
              <div className='tools-slides-cover'>
                {/* <Tool1></Tool1> */}
                <img src='./images/251.jpg' class='item-image '></img>
              </div>
            <h3 className='skill-h3 service-h3-new'>PC/HF/SS/003</h3>
          </article>
          <article className='single-service-new'>
    
              <div className='tools-slides-cover'>
                {/* <Tool1></Tool1> */}
                <img src='./images/252.jpg' class='item-image '></img>
              </div>
            <h3 className='skill-h3 service-h3-new'>PC/HF/SS/004</h3>
          </article>
                
                <article className='single-service-new'>
    
              <div className='tools-slides-cover'>
                {/* <Tool1></Tool1> */}
                <img src='./images/137.jpg' class='item-image '></img>
              </div>
            <h3 className='skill-h3 service-h3-new'>PC/HF/SS/005</h3>
          </article>
          <article className='single-service-new'>
    
              <div className='tools-slides-cover'>
                {/* <Tool1></Tool1> */}
                <img src='./images/138.jpg' class='item-image '></img>
              </div>
            <h3 className='skill-h3 service-h3-new'>PC/HF/SS/006</h3>
          </article>
          <article className='single-service-new'>
         
              <div className='tools-slides-cover'>
                {/* <Tool2></Tool2> */}
                <img src='./images/139.jpg' class='item-image '></img>
              </div>
            <h3 className='skill-h3 service-h3-new'>PC/HF/SS/007</h3>
          
          </article>
          <article className='single-service-new'>
         
              <div className='tools-slides-cover'>
                {/* <Tool2></Tool2> */}
                <img src='./images/140.jpg' class='item-image '></img>
              </div>
            <h3 className='skill-h3 service-h3-new'>PC/HF/SS/008</h3>
          
          </article>
          <article className='single-service-new'>
         
              <div className='tools-slides-cover'>
                {/* <Tool2></Tool2> */}
                <img src='./images/141.jpg' class='item-image '></img>
              </div>
            <h3 className='skill-h3 service-h3-new'>PC/HF/SS/009</h3>
          
          </article>
          <article className='single-service-new'>
    
              <div className='tools-slides-cover'>
                {/* <Tool1></Tool1> */}
                <img src='./images/253.jpg' class='item-image'></img>
              </div>
            <h3 className='skill-h3 service-h3-new'>PC/HF/SS/010</h3>
          </article>
          <article className='single-service-new'>
    
              <div className='tools-slides-cover'>
                {/* <Tool1></Tool1> */}
                <img src='./images/254.jpg' class='item-image '></img>
              </div>
            <h3 className='skill-h3 service-h3-new'>PC/HF/SS/011</h3>
          </article>
          <article className='single-service-new'>
    
              <div className='tools-slides-cover'>
                {/* <Tool1></Tool1> */}
                <img src='./images/255.jpg' class='item-image '></img>
              </div>
            <h3 className='skill-h3 service-h3-new'>PC/HF/SS/012</h3>
          </article>
          <article className='single-service-new'>
    
              <div className='tools-slides-cover'>
                {/* <Tool1></Tool1> */}
                <img src='./images/256.jpg' class='item-image '></img>
              </div>
            <h3 className='skill-h3 service-h3-new'>PC/HF/SS/013</h3>
          </article>
          <article className='single-service-new'>
    
              <div className='tools-slides-cover'>
                {/* <Tool1></Tool1> */}
                <img src='./images/268.jpg' class='item-image '></img>
              </div>
            <h3 className='skill-h3 service-h3-new'>PC/HF/SS/014</h3>
          </article>
          <article className='single-service-new'>
    
              <div className='tools-slides-cover'>
                {/* <Tool1></Tool1> */}
                <img src='./images/269.jpg' class='item-image '></img>
              </div>
            <h3 className='skill-h3 service-h3-new'>PC/HF/SS/015</h3>
          </article>
        
      </div>
         </div>
    </section>
  )
}

export default Team