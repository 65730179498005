import Carousel from 'react-bootstrap/Carousel';
const Tool1 = () => {
  return (
    <Carousel indicators={false} className='services-slides tools-slides'>
    <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/113.jpeg"
          alt="First slide"
        />
      </Carousel.Item>
   
   {/* <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/111.jpeg"
          alt="First slide"
        />
      </Carousel.Item> */}
     <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/99.jpg"
          alt="First slide"
        />
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/98.jpg"
          alt="First slide"
        />
      </Carousel.Item>
        <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/228.jpg"
          alt="First slide"
        />
      </Carousel.Item>
        <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/233.jpg"
          alt="First slide"
        />
      </Carousel.Item>

    </Carousel>
  )
}
export default Tool1