import Carousel from 'react-bootstrap/Carousel';
const Tool1 = () => {
  return (
    <Carousel indicators={false} className='services-slides tools-slides'>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/39.jpg"
          alt="First slide"
        />
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/194.jpg"
          alt="First slide"
        />
      </Carousel.Item>

       <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/195.jpg"
          alt="First slide"
        />
      </Carousel.Item>

       <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/196.jpg"
          alt="First slide"
        />
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/197.jpg"
          alt="First slide"
        />
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/198.jpg"
          alt="First slide"
        />
      </Carousel.Item>

    </Carousel>
  )
}
export default Tool1