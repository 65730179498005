import Carousel from 'react-bootstrap/Carousel';
const Tool1 = () => {
  return (
    <Carousel indicators={false} className='services-slides tools-slides'>
    

      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/22.jpg"
          alt="First slide"
        />
      </Carousel.Item>

      {/* <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/23.jpg"
          alt="First slide"
        />
      </Carousel.Item> */}
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/53.jpg"
          alt="First slide"
        />
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/120.jpg"
          alt="First slide"
        />
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/121.jpg"
          alt="First slide"
        />
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/122.jpg"
          alt="First slide"
        />
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/123.jpg"
          alt="First slide"
        />
      </Carousel.Item>

    </Carousel>
  )
}
export default Tool1